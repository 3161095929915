import React, { useState, useEffect, ReactNode } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Navbar from "../components/navbar";
import Dashboard from "../components/admin/dashbord";
import AreaManagement from "../components/admin/areaManagement";
import ExceptionHandling from "../components/admin/exceptionHandling";
import LabReport from "../components/lab_operator/labReport";
import IncubationArea from "../components/operators/indubationArea";
import AutoClave from "../components/operators/autoClave";
import LabTestArea from "../components/operators/labtestArea";
import ScanArea from "../components/operators/scanArea";
import UserManagement from "../components/admin/userManagement";
import PolicyManagement from "../components/admin/policyManagement/Index";
import UpdatePassword from "./UpdatePassword";
import Signin from "./Signin";
import ExposureTable from "../components/admin/dashbord/assets/exposureTable";
import IncubationTable from "../components/admin/dashbord/assets/incubationTable";
import LabtestTable from "../components/admin/dashbord/assets/labtestTable";
import AutoclaveTable from "../components/admin/dashbord/assets/AutoClaveTable";
import LabOperatorDashboard from "../components/lab_operator/dashboard";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import CustomLoader from "../widgets/loader/loader";
import InnerPageExposure from "../components/admin/dashbord/exposureDetails/innerPageView";
import InnerPageIncubation from "../components/admin/dashbord/incubationDetails/innerPageView";
import InnerPageDescrepency from "../components/admin/dashbord/discrepancyDetails/innerPageDetails";
import InnerPageAutoclave from "../components/admin/dashbord/autoclaveDetails/innerPageView";
import InnerPageLabtest from "../components/admin/dashbord/labtestDetails/innerViewDetails";
import ExposureHistoryTable from "../components/admin/historicalDashboard/assets/ExposureHistoryTable";
import AutoclaveHistoryTable from "../components/admin/historicalDashboard/assets/AutoclaveHistoryTable";
import LabtestHistoryTable from "../components/admin/historicalDashboard/assets/LabtestHistoryTable";
import IncubationHistoryTable from "../components/admin/historicalDashboard/assets/IncubationHistoryTable";
import UpcommingSchedule from "../components/admin/dashbord/exposureDetails/upcommingSchedule";
import MissedSchedule from "../components/admin/dashbord/exposureDetails/missedSchedule";
import { FilterProvider } from "../components/admin/dashbord/partials/FilterContext";
import AnalyticDashboard from "../components/admin/analyticsDashboard";
import TrendAnalytics from "../components/admin/analyticsDashboard/reports/trendAnalytics";
import ExcursionReport from "../components/admin/analyticsDashboard/reports/excursionReport";
import SummaryReport from "../components/admin/analyticsDashboard/reports/summaryReport";
import LandingComponent from "../components/admin/analyticsDashboard/reports/Violation/landingComponent";
import OperatorSchedule from "../components/admin/dashbord/operatorSchedule";
import EnvironmentalFlora from "../components/operators/environmentalFlora";
import SubNavigationComponent from "../components/admin/analyticsDashboard/partials/landingPage";
import EquipmentCalibration from "../components/admin/userEndConfig/equipmentCalibration";
import AddRawMaterials from "../components/admin/userEndConfig/addRawMaterials";
import AuditTrialReport from "../components/admin/auditTrialReport";
import UserAccessReport from "../components/admin/userAccessReport";
import RolesManagement from "../components/admin/systemConfigurations/rolesAndPermission";
import { userService } from "../service/userService";
import Footer from "./Footer";
import GradeConfiguration from "../components/admin/userEndConfig/gradeConfiguration";
import DeviceRegistration from "../components/admin/systemConfigurations/deviceRegistration";
import LogReport from "../components/admin/logReport";
import TestTubeConfiguration from "../components/admin/testTubeConfig";
import VisualInspectionTest from "../components/admin/visualInspectionTest";
import WorkflowDesign from "../components/admin/workflow/partials";
import DeviationPage from "../components/admin/incident_devidation";
import PlateInventoryV2 from "../components/admin/plate_inventory_v2";
import PlateReconcilation from "../components/admin/plateReconcilation";
import SopReport from "../components/admin/sop";
import PlateReconcilationSummary from "../components/admin/plateReconcilation/summary_v2";
// import PlateReconcilationSummary from "../components/admin/plateReconcilation/summary";
import ResetPassword from "./ResetPassword";
import ActiveAirSamplingReport from "../components/admin/reportpdf/summaryReport/activeSamplingReport";
import PassiveAIrSampingReport from "../components/admin/reportpdf/summaryReport/passiveSamplingReport";
import PersonnelMonitoring from "../components/admin/reportpdf/summaryReport/personnelMonitoring";
import SurfaceMonitoring from "../components/admin/reportpdf/summaryReport/surfaceMonitoring";
import EquipmentConfiguration from "../components/admin/reportpdf/summaryReport/equipementConfiguration";
import DeviationReport from "../components/admin/reportpdf/deviation";
import VisualInspection from "../components/admin/reportpdf/visualInspectionReport";
import PlateActivityreport from "../components/admin/reportpdf/summaryReport/plateActivityLog";
import ScheduleFrequency from "../components/admin/activitySchedule/scheduleFrequency";
import ScheduleProduction from "../components/admin/activitySchedule/scheduleProduction";
// import ScheduleOperator from "../components/admin/activitySchedule/scheduleOperator";
import ScheduleOperator from "../components/admin/activitySchedule/scheduleOperatorV2";
import PlateReconcilationTreeTable from "../components/admin/plateReconcilation_V2";
import HolidayRegister from "../components/admin/systemConfigurations/holidayCalender";
import WelcomeNote from "../components/common/welcomePage";
import Discard from "../components/admin/discard";
import NotificationConfiguration from "../components/admin/notificationConfiguration";
import PersonnelMonitoringLocation from "../components/admin/userEndConfig/personnelMonitoring";
import SurfaceMonitoringReport from "../components/admin/reportpdf/summaryReport/surfaceMonitoringReport_v2";
import ShiftRegister from "../components/admin/systemConfigurations/shiftManagement";
import GenerateQrPlate from "../components/admin/activitySchedule/GenerateQrPlate";
import TestTubeSwabConfiguration from "../components/admin/testTubeSwabConfig";
import SwabStickReconciliation from "../components/admin/testTubeSwabConfig/reconciliation";
import TestTubeReconciliation from "../components/admin/testTubeConfig/reconciliation";
import BatchSummaryReport from "../components/admin/reportpdf/summaryReport/batchSummaryReport";
import TemporaryManagement from "../components/admin/temporaryManagement";
import DownloadReports from "../components/admin/downloadpdf";
import ScheduleCalendar from "../components/admin/activitySchedule/scheduleProduction/partials/scheduleCalendar";

const PortalScreen = () => {
  const loading = useSelector((state: RootState) => state.loader.loading);
  // const [showNavbar, setShowNavbar] = useState(true);
  const showNavbar = useSelector((state: RootState) => state.navbar.showNavbar);
  const [permission, setPermission] = useState<any>([]);

  useEffect(() => {
    var storedData = localStorage.getItem("userDetails");
    if (storedData) {
      var storedObject = JSON.parse(storedData);
      getPermissionByRole(storedObject[0].role_master_id);
    }
  }, []);

  //get permission by role
  const getPermissionByRole = async (role: number) => {
    try {
      const response = await userService.getPermissionByRole(role);
      if (response.status && response.statusCode === 200) {
        setPermission(response.data.permissions);
        // try {
        //     if ( Array.isArray(response.data) && response.data?.length > 0) {
        //       let rowData: any = response.data.map((element: any, index: number) => {

        //         return {
        //           // slno: index + 1,
        //           label: element.title,
        //           value : element.id,
        //           // status : element.status,
        //         };
        //       });
        //       // setUserRoleList(rowData);
        //     }
        //   } catch (error) {
        //     console.log(error);
        //   }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FilterProvider>
      <BrowserRouter>
        <div className="row">
          {showNavbar && (
            <div className="col m-0 p-0">
              <Navbar />
            </div>
          )}

          {/* {loading && (
                 <CustomLoader />
                )} */}
          <div
            className={` m-0 p-0 ${showNavbar ? "col-9" : ""}`}
            style={showNavbar ? { width: "82%" } : {}}
          >
            <div style={{"minHeight": "85vh"}}>
            <Routes>
              <>
                {(permission?.includes("realtime_dashboard") ||
                  permission?.includes("historical_dashboard") ||
                  permission?.includes("analytics_dashboard")) && (
                  <Route path="/adminDashboard" element={<Dashboard />} />
                )}
                {permission?.includes("realtime_dashboard") && (
                  <Route path="/resetPassword" element={<ResetPassword />} />
                )}
                {permission?.includes("realtime_dashboard") && (
                  <Route path="/workFlow" element={<WorkflowDesign />} />
                )}
                {permission?.includes("list_area") && (
                  <Route path="/areaManagement" element={<AreaManagement />} />
                )}
                {permission?.includes("list_user") && (
                  <Route path="/userManagement" element={<UserManagement />} />
                )}
                {permission?.includes("list_policy") && (
                  <Route
                    path="/policyManagement"
                    element={<PolicyManagement />}
                  />
                )}
                {permission?.includes("list_plates") && (
                  <Route
                    path="/plateInventory"
                    element={<PlateInventoryV2 />}
                  />
                )}
                {permission?.includes("list_tcm") && (
                  <Route
                    path="/temporaryManagement"
                    element={<TemporaryManagement />}
                  />
                )}
                {permission?.includes("list_plates") && (
                  <Route
                    path="/discard"
                    element={<Discard />}
                  />
                )}
                {permission?.includes("view_plate_reconciliation") && (
                  <Route
                    path="/plateReconcilation"
                    element={<PlateReconcilationTreeTable />}
                  />
                )}
                {permission?.includes("view_plate_reconciliation") && (
                  <Route
                    path="/reconcilationSummary"
                    element={<PlateReconcilationSummary />}
                  />
                )}
                {permission?.includes("view_test_tube_reconciliation") && (
                  <Route
                    path="/testTubeReconcilation"
                    element={<TestTubeReconciliation/>}
                  />
                )}
                {permission?.includes("view_swab_stick_reconciliation") && (
                  <Route
                    path="/swabReconcilation"
                    element={<SwabStickReconciliation/>}
                  />
                )}
                {permission?.includes("view_reports") && (
                  <Route
                    path="/airSamplingreport"
                    element={<ActiveAirSamplingReport />}
                  />
                )}
                {permission?.includes("view_reports") && (
                  <Route
                    path="/batchSummaryReport"
                    element={<BatchSummaryReport />}
                  />
                )}
                {permission?.includes("view_reports") && (
                  <Route
                    path="/passiveSamplingreport"
                    element={<PassiveAIrSampingReport />}
                  />
                )}
                {permission?.includes("view_reports") && (
                  <Route
                    path="/personnelMonitoringReport"
                    element={<PersonnelMonitoring />}
                  />
                )}
                {permission?.includes("view_reports") && (
                  <Route
                    path="/surfaceMonitoring"
                    element={<SurfaceMonitoring />}
                  />
                )}
                {permission?.includes("view_reports") && (
                  <Route
                    path="/surfaceMonioringReport"
                    element={<SurfaceMonitoringReport />}
                  />
                )}
                {permission?.includes("view_reports") && (
                  <Route
                    path="/equipmentReport"
                    element={<EquipmentConfiguration />}
                  />
                )}
                {permission?.includes("view_reports") && (
                  <Route
                    path="/deviationReport"
                    element={<DeviationReport />}
                  />
                )}
                {permission?.includes("view_reports") && (
                  <Route
                    path="/visualInspectionReport"
                    element={<VisualInspection />}
                  />
                )}
                {permission?.includes("list_test_tube") && (
                  <Route
                    path="/testTubeInventory"
                    element={<TestTubeConfiguration />}
                  />
                )}
                {permission?.includes("list_swab_stick") && (
                  <Route
                    path="/testTubeSwab"
                    element={<TestTubeSwabConfiguration />}
                  />
                )}
                {permission?.includes("list_visual_inspection") && (
                  <Route
                    path="/visualInspectionTest"
                    element={<VisualInspectionTest />}
                  />
                )}
                {permission?.includes("view_reports") && (
                  <Route
                    path="/plateActivityReport"
                    element={<PlateActivityreport />}
                  />
                )}
                {permission?.includes("list_incident") && (
                  <Route
                    path="/deviationReporting"
                    element={<DeviationPage />}
                  />
                )}
                {permission?.includes("list_exceptions") && (
                  <Route
                    path="/exceptionHandling"
                    element={<ExceptionHandling />}
                  />
                )}
                {permission?.includes("list_equipment") && (
                  <Route
                    path="/equipmentCalibration"
                    element={<EquipmentCalibration />}
                  />
                )}
                {permission?.includes("list_rawmaterial_type") && (
                  <Route
                    path="/addRawMaterials"
                    element={<AddRawMaterials />}
                  />
                )}
                {permission?.includes("list_rawmaterial_type") && (
                  <Route
                    path="/addGradeType"
                    element={<GradeConfiguration />}
                  />
                )}
                {permission?.includes("list_personnel_monitoring") && (
                  <Route
                    path="/personnelMonitoringLocation"
                    element={<PersonnelMonitoringLocation />}
                  />
                )}
                {permission?.includes("view_configuration_change_report") && (
                  <Route
                    path="/auditTrialReport"
                    element={<AuditTrialReport />}
                  />
                )}
                {permission?.includes("view_user_access_report") && (
                  <Route
                    path="/userAccessReport"
                    element={<UserAccessReport />}
                  />
                )}
                {permission?.includes("list_log_report") && (
                  <Route path="/logReport" element={<LogReport />} />
                )}
                {permission?.includes("view_sop") && (
                  <Route path="/sopReport" element={<SopReport />} />
                )}
                {permission?.includes("list_roles") && (
                  <Route
                    path="/rolesAndPermission"
                    element={<RolesManagement />}
                  />
                )}
                {permission?.includes("list_device") && (
                  <Route
                    path="/deviceRegistration"
                    element={<DeviceRegistration />}
                  />
                )} 
                {permission?.includes("list_holiday") && (
                  <Route
                    path="/holidayRegister"
                    element={<HolidayRegister />}
                  />
                )}
                {permission?.includes("list_notification") && (
                  <Route
                    path="/notificationConfiguration"
                    element={<NotificationConfiguration />}
                  />
                )}
                {permission?.includes("list_shift") && (
                  <Route
                    path="/shiftRegister"
                    element={<ShiftRegister />}
                  />
                )}
                {/*Dashboard starts here */}
                {/* {permission?.includes("realtime_dashboard") && (
                  <Route path="*" element={<Navigate to="/adminDashboard" />} />
                )} */}
                {(permission?.includes("realtime_dashboard") ||
                permission?.includes("historical_dashboard") ||
                permission?.includes("analytics_dashboard")) ? (
                  <Route path="*" element={<Navigate to="/adminDashboard" />} />
                ) : (
                  <Route path="*" element={<WelcomeNote />} />
                )}

                <Route path="/exposure-table" element={<ExposureTable />} />
                <Route path="/missed-Schedule" element={<MissedSchedule />} />
                <Route
                  path="/upcoming-Schedule"
                  element={<UpcommingSchedule />}
                />
                <Route path="/incubation-table" element={<IncubationTable />} />
                <Route path="/labtest-table" element={<LabtestTable />} />
                <Route path="/autoclave-table" element={<AutoclaveTable />} />
                <Route
                  path="/inner-view-exposure/:exposureId"
                  element={<InnerPageExposure />}
                />
                <Route
                  path="/inner-view-incubation/:incubationId"
                  element={<InnerPageIncubation />}
                />
                <Route
                  path="/inner-view-descrepency/:descrepencyId"
                  element={<InnerPageDescrepency />}
                />
                <Route
                  path="/inner-view-autoclave/:autoclaveId"
                  element={<InnerPageAutoclave />}
                />
                <Route
                  path="/inner-view-labtest/:labtestId"
                  element={<InnerPageLabtest />}
                />

                {/*Historical Dashboard starts here */}
                {permission?.includes("historical_dashboard") && (
                  <>
                    <Route
                      path="/exposure-history-table"
                      element={<ExposureHistoryTable />}
                    />
                    <Route
                      path="/incubation-history-table"
                      element={<IncubationHistoryTable />}
                    />
                    <Route
                      path="/labtest-history-table"
                      element={<LabtestHistoryTable />}
                    />
                    <Route
                      path="/autoclave-history-table"
                      element={<AutoclaveHistoryTable />}
                    />
                  </>
                )}

                {/* Analytics Dashboard starts here */}
                {permission?.includes("analytics_dashboard") && (
                  <>
                    <Route
                      path="/analytics-dashboard"
                      element={<AnalyticDashboard />}
                    />
                    <Route
                      path="/trend-analytics/:bulletId"
                      element={<TrendAnalytics />}
                    />
                    <Route
                      path="/excursion-report/:bulletId"
                      element={<ExcursionReport />}
                    />
                    <Route
                      path="/summary-report/:bulletId"
                      element={<SummaryReport />}
                    />
                    <Route
                      path="/violation-home/:bulletId/:trendBy"
                      element={<LandingComponent />}
                    />
                    <Route
                      path="/operator-schedule"
                      element={<OperatorSchedule />}
                    />
                    <Route
                      path="/inner-landing/:areaId"
                      element={<SubNavigationComponent />}
                    />
                  </>
                )}
                {permission?.includes("scan_incubation") && (
                  <Route path="/incubationArea" element={<IncubationArea />} />
                )}
                {permission?.includes("scan_autoclave") && (
                  <Route path="/autoClave" element={<AutoClave />} />
                )}
                {permission?.includes("scan_labtest") && (
                  <Route path="/labtestArea" element={<LabTestArea />} />
                )}
                {(permission?.includes("scan_incubation") ||
                  permission?.includes("scan_labtest") ||
                  permission?.includes("environmental_flora_identification") ||
                  permission?.includes("scan_autoclave")) && (
                    <Route path="/scanArea" element={<ScanArea />} />
                  )}
                {(permission?.includes("scan_incubation") ||
                  permission?.includes("scan_labtest") ||
                  permission?.includes("environmental_flora_identification") ||
                  permission?.includes("scan_autoclave")) ? (
                    <Route path="*" element={<Navigate to="/scanArea" />} />
                  ): (
                    <Route path="*" element={<WelcomeNote />} />
                  )}
                {permission?.includes("environmental_flora_identification") && (
                  <Route
                    path="/environmental-flora"
                    element={<EnvironmentalFlora />}
                  />
                )}

                {/* <Route path="/labOperatorDashboard" element={<LabOperatorDashboard />} /> */}
                {permission?.includes("qa_approval") && (
                  <Route path="/labReport" element={<LabReport />} />
                )}
                {permission?.includes("qa_approval") ? (
                  <Route path="*" element={<Navigate to="/labReport" />} />
                ): (
                  <Route path="*" element={<WelcomeNote />} />
                )}

                {(permission?.includes("list_frequency_schedule")) && (
                  <Route
                    path="/schedule_frequency"
                    element={<ScheduleFrequency />}
                  />
                )}
                {(permission?.includes("list_production_schedule")) && (
                  <Route
                    path="/schedule_production"
                    element={<ScheduleProduction />}
                  />
                )}
                {(permission?.includes("list_operator_schedule")) && (
                  <Route
                    path="/schedule_operator"
                    element={<ScheduleOperator />}
                  />
                )}
                {(permission?.includes("view_production_calendar")) && (
                  <Route
                    path="/productionCalendar"
                    element={<ScheduleCalendar />}
                  />
                )}
                 {(permission?.includes("generate_plate_qr")) && (
                  <Route
                    path="/generatePlateQr"
                    element={<GenerateQrPlate />}
                  />
                )}
                {permission?.includes("list_downloads") && (
                  <Route
                    path="/downloadReports"
                    element={<DownloadReports />}
                  />
                )}
              </>
            </Routes>
            </div>
            <Footer />
          </div>
        </div>
      </BrowserRouter>
    </FilterProvider>
  );
};

export default PortalScreen;
