import React, { useEffect, useState } from 'react'
import HeaderSection from '../../../common/headerSection'
import PageHeader from '../../../common/pageHeader'
import CustomToast from '../../../../widgets/toaster'
import CustomTable from '../../../../widgets/table'
import { useTranslation } from 'react-i18next'
import Pagination from '../../../../widgets/pagination'
import {
    columns,
    addedOperatorsColumns,
    reassignOperatorsColumns,
  } from "./partials/columns";
import { helperFunctions as _h } from "../../../../utils/helper";
import { userService } from '../../../../service/userService'
import moment from 'moment'
import { scheduleService } from '../../../../service/scheduleService'
import { TfiClose } from 'react-icons/tfi'
import { Modal } from 'react-bootstrap'
import AlertModal from '../../../../widgets/alertModal'
import { areaService } from '../../../../service/areaServices'
import { shiftManagement } from '../../../../service/shiftManagement'
import { DEFAULT_PAGE_SIZE } from '../../../../constants/constants'



const ScheduleOperatorV2 = () => {

    const { t } = useTranslation();

    const defaultFormData = {
        id: "",
        activity_type_id: "",
        plate_count: 0,
        reason: "",
        status: "",
        created_at: "",
        updated_at: "",
        created_by: "",
        updated_by: "",
        activity_type_name: "",
        schedule_production_id: "",
        start_date_time: "",
        start_date_time_copy: "",
        end_date_time: "",
        process_id: "",
        user_master_id: "",
        production_schedule_name: "",
        product_name: "",
        production_type: "",
        user_first_name: "",
        user_last_name: "",
        operator_name: "",
        process_name: "",
        area_id: "",
        area_name: "",
        location_id: "",
        work_shift_id: "",
        location_name: "",
        work_shift_name: "",
        user_name: "",
      };
      
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0]; // YYYY-MM-DD
    const formattedTime = currentDate.toTimeString().split(' ')[0]; // HH:MM:SS

    const date7DaysAgo = new Date();
    date7DaysAgo.setDate(date7DaysAgo.getDate() - 7);
    const formattedDate7DaysAgo = date7DaysAgo.toISOString().split("T")[0];

    const dateTime = `${formattedDate7DaysAgo} 00:00:00`;

    // end date should be 1 week ahead from currentDate
    const endDate = new Date(currentDate);
    // endDate.setDate(endDate.getDate() + 7);
    const formattedEndDate = endDate.toISOString().split('T')[0]; // YYYY-MM-DD
    const formattedEndTime = currentDate.toTimeString().split(' ')[0]; // HH:MM:SS
    const endDateTime = `${formattedEndDate} 23:59:59`;

    //toaster section
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastType, setToastType] = useState("");

    // table pagination
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);

    const [tableData, setTableData] = useState<Array<any>>([]);
    const [useEffectRequired, setUseEffectRequired] = useState(false);
    const [permission, setPermission] = useState<any>([]);
    const [keyWord, setkeyWord] = useState("");
    const [sort, setSort] = useState("id");
    const [order, setOrder] = useState("desc");
    const [addedOperators, setAddedOperators] = useState<Array<any>>([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [masterFormData, setMasterFormData] = useState(defaultFormData);
    const [modalName, setModalName] = useState("");
    const [selectedId, setSelectedId] = useState("");
    const [updatedStatus, setUpdatedStatus] = useState("");
    const [statusChangeAlert, setStatusChangeAlert] = useState(false);
    const [reassignOperatorSchedules, setReassignOperatorSchedules] = useState<
        Array<any>
    >([]);
    const [showReassignModal, setShowReassignModal] = useState(false);
    const [showAssignModal, setShowAssignModal] = useState(false);
    //handle error section
    interface Errors {
        activity_type_id?: string;
        reason?: string;
        status?: string;
        schedule_production_id?: string;
        start_date_time?: string;
        end_date_time?: string;
        process_id?: string;
        user_master_id?: string;
        location_id?: string;
        work_shift_id?: string;
        added_operators?: string;
        assignUser_id?: string;
        newStartDate?: string;
        plate_count?: string;
    }
    const [errors, setErrors] = useState<Errors>({});
    const defaultReassignFormData = {
        start_date_time: "",
        end_date_time: "",
        new_start_time: "",
        user_master_id: "",
        assignUser_id: "",
      };
    const [reassignFormData, setReassignFormData] = useState(
        defaultReassignFormData
      );
    const [locationList, setLocationList] = useState<Array<any>>([]);
    const [selectedLocation, setSelectedLocation] = useState<any>([]);
    const [workShiftList, setWorkShiftList] = useState<Array<any>>([]);
    const [activityType, setActivityType] = useState<Array<any>>([]);
    const [processList, setProcessList] = useState<Array<any>>([]);
    const [prodScheduleList, setProdScheduleList] = useState<Array<any>>([]);
    const [usersList, setUsersList] = useState<Array<any>>([]);
    const [scheduledOperatorIds, setScheduledOperatorIds] = useState<Array<any>>([]);
    const [showAddAreaAlert, setShowAddAreaAlert] = useState(false);
    const [assigSubmitAlert, setAssigSubmitAlert] = useState(false);
    const [reAssigSubmitAlert, setReAssigSubmitAlert] = useState(false);
    const [actionClearAll, setActionClearAll] = useState(false);

    const [activityTypeId, setActivityTypeId] = useState("");
    const [processId, setProcessId] = useState("");
    const [proScheduleId, setProScheduleId] = useState("")
    const [assignee, setAssignee] = useState("");
    const [status, setStatus] = useState("active,inactive");
    const [fromDate, setFromDate] = useState(dateTime);
    const [toDate, setToDate] = useState(endDateTime);
    const [areaData, setAreaData] = useState([]);
    const [selectedOperatorIds, setSelectedOperatorIds] = useState([]);
    const [areaId, setAreaId] = useState("");

    












    const handleViewClick = (id: string, viewValue: boolean) => {
        const row = tableData.filter((item) => item.slno === id);
        if (_h.arrayHasData(row) && _h.objectHasValue(row[0])) {
          console.log("handleEditClick=>row[0]", row[0]);
        //   setSelectedRow(row[0]);
        //   setShowViewModal(true);
        }
      };

    //get permission by role
    const getPermissionByRole = async (role: number) => {
        try {
        const response = await userService.getPermissionByRole(role);
        if (response.status && response.statusCode === 200) {
            setPermission(response.data.permissions);
        }
        } catch (error) {
        console.error(error);
        }
    };

    //table filter section
    const handleFilter = (key: string, value: any) => {
        if (key === "search") {
          setkeyWord(value);
        } else if (key === "activityType") {
          setActivityTypeId(value)
          getMasterProcessList(value)
        // } else if (key === "process") {
        //   setProcessId(value)
        } else if (key === "area") {
          setAreaId(value)
        } else if (key === "productionSchedule") {
          setProScheduleId(value)
        // } else if (key === "status") {
        //   setStatus(value)
        } else if (key === "assignee") {
          setAssignee(value)
        } else if (key === "FromDate") {
          const formattedDate = value ? moment(value).format("YYYY-MM-DD 00:00:00") : "";
          setFromDate(formattedDate)
          setToDate("")
        } else if (key === "ToDate") {
          const formattedDate = value ? moment(value).format("YYYY-MM-DD 23:59:59") : "";
          setToDate(formattedDate)
        }
        setUseEffectRequired(true);
    };

    // function to sort table
    const handleSort = (key: string, value: boolean) => {
        setSort(key);
        if (value) {
        setOrder("asc");
        } else {
        setOrder("desc");
        }
        setUseEffectRequired(true);
    };

    //handle table pagination
    const handlePageChange = (newPage: any, pageSize?: number) => {
        setPage(newPage);
        if (pageSize) {
        setPageSize(pageSize);
        }
        setUseEffectRequired(true);
    };

    // get ProductionScheduleList
    const getProductionSchedules = async () => {
        try {
        const response = await scheduleService.getProductionSchedules();
        if (response.status && response.statusCode === 200) {
            try {
            if (Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map(
                (element: any, index: number) => {
                    return {
                    slno: index + 1,
                    id: element.id,
                    production_schedule_name: element?.production_schedule_name,
                    activity_type_id: element.activity_type_id,
                    };
                }
                );
                setProdScheduleList(rowData);
            }
            } catch (error) {
            console.log(error);
            }
        } else {
            setProdScheduleList([]);
        }
        } catch (error) {
        console.error("user response", error);
        }
    };

    // get_locations();
    const get_work_shifts = async () => {
        try {
        // const response = await scheduleService.get_work_shifts();
        const response = await shiftManagement.getShiftDropDown();
        if (response.status && response.statusCode === 200) {
            try {
            if (Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map(
                (element: any, index: number) => {
                    return {
                    slno: index + 1,
                    id: element.id,
                    name: element.name,
                    };
                }
                );
                setWorkShiftList(rowData);
            }
            } catch (error) {
            console.log(error);
            }
        } else {
            setWorkShiftList([]);
        }
        } catch (error) {
        console.error("user response", error);
        }
    };

    // get Activity Type List
    const getActivityTypeList = async () => {
        try {
        const response = await scheduleService.getActivityTypeList();
        if (response.status && response.statusCode === 200) {
            try {
            if (Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map(
                (element: any, index: number) => {
                    return {
                    slno: index + 1,
                    id: element.id,
                    name: element.name,
                    };
                }
                );
                setActivityType(rowData);
            }
            } catch (error) {
            console.log(error);
            }
        } else {
            setActivityType([]);
        }
        } catch (error) {
        console.error("user response", error);
        }
    };

    // get locations List
    const getAreaList = async () => {
      try {
        const response = await areaService.getAreaList(0, 0, sort , order , "", "8,12,9,6");
        if(response.status && response.statusCode === 200){
          try {
              if ( Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map((element: any, index: number) => {

                  const role = element.role_master_id === "1" ?
                  "Admin" : element.role_master_id === "2" ?
                  "Operator" : element.role_master_id === "3"? 
                  "Lab Operator": null
                  const statusNumber = parseInt(element.type, 10); 
                  const area_type = areaService.getLocationStatus(statusNumber);

                  return { 
                      slno: index + 1, 
                      id: element.id,
                      area_code: element.area_code,
                      name: element.name,
                      area_type: element.location_type_name,
                      status : element.status,
                      qr_image_path : element.qr_image_path,
                  };
                });
                setAreaData(rowData);
              }
            } catch (error) {
              console.log(error);
            }
        } else {
          setAreaData([]);
        }
      } catch (error) {
        console.error('user response', error);
      }
  };


    // getMasterUserList()
    const getMasterUserList = async () => {
        try {
        const response = await scheduleService.getMasterUserList();
        if (response.status && response.statusCode === 200) {
            try {
            if (Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map(
                (element: any, index: number) => {
                    return {
                    slno: index + 1,
                    id: element.id,
                    name: (
                        (element?.first_name).trim() +
                        " " +
                        (element?.last_name).trim()
                    ).trim(),
                    };
                }
                );
                setUsersList(rowData);
            }
            } catch (error) {
            console.log(error);
            }
        } else {
            setUsersList([]);
        }
        } catch (error) {
        console.error("user response", error);
        }
    };

    //open add area modal
    const handleAddClick = () => {
        setAddedOperators([]);
        setShowAddModal(true);
        setModalName("Create Task");
        setProcessList([]);
    };

    const handleCommonFormValidation = () => {
        const errors: Errors = {};
        // if (!masterFormData.schedule_production_id) {
        //   errors.schedule_production_id = "Please select a production schedule";
        // }
        if (!masterFormData.start_date_time) {
          errors.start_date_time = "Please select a start date time";
        }
        if (masterFormData.plate_count <= 0) {
          errors.plate_count = "Please enter plate quantity";
        }
        // if (!masterFormData.end_date_time) {
        //   errors.end_date_time = "Please select an end date time";
        // }
        if (!masterFormData.activity_type_id) {
          errors.activity_type_id = "Please select an activity type";
        }
        // if (!masterFormData.process_id) {
        //   errors.process_id = "Please select a process";
        // }
        if (masterFormData.area_id && !masterFormData.location_id) {
          errors.location_id = "Please select a location";
        }
        // if (!masterFormData.work_shift_id) {
        //   errors.work_shift_id = "Please select a work shift";
        // }
        // if (!masterFormData.user_master_id) {
        //   errors.user_master_id = "Please select an operator";
        // }
        if (
          _h.isNotEmpty(masterFormData.start_date_time)
        ) {
        //   let fromEpoch = new Date(masterFormData.start_date_time).valueOf();
        //   let toEpoch = new Date(masterFormData.end_date_time).valueOf();
        //   if (fromEpoch >= toEpoch) {
        //     errors.start_date_time = "Start date should be less than End date";
        //     errors.end_date_time = "End date should be greater than Start date";
        //   }
        }
        if (masterFormData.id && !masterFormData.reason) {
          errors.reason = "Please enter reason for edit";
        }
        if (Object.keys(errors).length > 0) {
          setErrors(errors);
          return false;
        }
        setErrors({});
        return true;
      };

    const handleStatusChange = (id: string, status: string) => {
        setSelectedId(id);
        if (status === "active") {
        setUpdatedStatus("inactive");
        } else {
        setUpdatedStatus("active");
        }
        setStatusChangeAlert(true);
    };

    //filter selected id based on the row selected while editing
    const handleEditClick = (id: string) => {
        const row = tableData.filter((item) => item.slno === id);
        if (_h.arrayHasData(row) && _h.objectHasValue(row[0])) {
        console.log("handleEditClick=>row[0]", row[0]);
        setMasterFormData(row[0]);
        setShowAddModal(true);
        setModalName("Edit Operator Schedule");
        }
    };

    const handleReassignOperator = () => {
        console.error("Operator not found");
        setReassignOperatorSchedules([]);
        setShowReassignModal(true);
        setModalName("Reassign Operator");
      };

    // get table data
    const getOperatorScheduleList = async () => {
        try {
        const response = await scheduleService.getOperatorScheduleList(
            page,
            pageSize,
            sort,
            order,
            keyWord,
            proScheduleId,
            activityTypeId,
            processId,
            status,
            assignee,
            fromDate,
            toDate,
            areaId
        );
        if (response.status && response.statusCode === 200) {
            try {
            if (Array.isArray(response.data) && response.data?.length > 0) {
                setTotalPages(response?.totalPages);
                setTotalElements(response?.totalElements);
                let rowData: any = response.data.map(
                (element: any, index: number) => {
                    return {
                    slno: index + 1,
                    id: element.id,
                    status: element.status,
                    activity_type_id: element.activity_type_id,
                    created_at: element?.created_at
                        ? moment(element?.created_at, "YYYY-MM-DD HH:mm:ss").format(
                            "DD-MM-YYYY - HH:mm:ss"
                        )
                        : "-",
                    updated_at: element?.updated_at
                        ? moment(element?.updated_at, "YYYY-MM-DD HH:mm:ss").format(
                            "DD-MM-YYYY - HH:mm:ss"
                        )
                        : "-",
                    schedule_production_id: element?.schedule_production_id,
                    start_date_time: element?.start_date_time
                        ? moment(
                            element?.start_date_time,
                            "YYYY-MM-DD HH:mm:ss"
                        ).format("DD-MM-YYYY")
                        : "-",
                    end_date_time: element?.end_date_time
                        ? moment(
                            element?.end_date_time,
                            "YYYY-MM-DD HH:mm:ss"
                        ).format("DD-MM-YYYY - HH:mm:ss")
                        : "-",
                    process_id: element?.process_id,
                    user_master_id: element?.user_master_id,
                    created_by: element?.created_by,
                    updated_by: element?.updated_by,
                    production_schedule_name: element?.production_schedule_name,
                    product_name: element?.product_name,
                    production_type: element?.production_type,
                    user_first_name: element?.user_first_name,
                    user_last_name: element?.user_last_name,
                    // operator_name: (
                    //     (element?.user_first_name).trim() +
                    //     " " +
                    //     (element?.user_last_name).trim()
                    // ).trim(),
                    operator_name: `${element?.user_first_name || ""} ${element?.user_last_name || ""}`,
                    process_name: element?.process_name,
                    activity_type_name: element?.activity_type_name,
                    area_name: element?.area_name,
                    location_name: element?.location_name,
                    work_shift_name: element?.work_shift_name,
                    };
                }
                );
                setTableData(rowData);
            }
            } catch (error) {
            console.log(error);
            }
        } else {
            setTableData([]);
            setTotalPages(0);
            setTotalElements(0);
        }
        } catch (error) {
        console.error("user response", error);
        }
    };

    //clear modal on close
    const handleFormClose = () => {
        setShowAddModal(false);
        setShowAssignModal(false);
        setErrors({});
        setMasterFormData(defaultFormData);
        setAddedOperators([]);
        setShowReassignModal(false);
        setReassignFormData(defaultReassignFormData);
        setReassignOperatorSchedules([]);
        setProcessList([]);
    };

     // get_locations();
    const get_locations = async (filters: any) => {
        try {
        // const response = await scheduleService.get_locations(filters);
        const response = await areaService.getLocationByActivity(filters);
        if (response.status && response.statusCode === 200) {
            try {
            if (Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map(
                (element: any, index: number) => {
                    return {
                    slno: index + 1,
                    id: element.id,
                    name: element.name,
                    };
                }
                );
                setLocationList(rowData);
            }
            } catch (error) {
            console.log(error);
            }
        } else {
            setLocationList([]);
        }
        } catch (error) {
        console.error("user response", error);
        }
    };

    const areaListing = async (id:any) => {
      try {
        const response = await areaService.getAreaId(id);
        if (response.status && response.statusCode === 200) {
                let rowData = {
                    slno: response.data + 1,
                    id: response.data.id,
                    locationActivityId : response.data.location_activity_id,
                    locationTypeName : response.data.location_type_name,
                    name: response.data.name,
                    };
                setSelectedLocation(rowData);
                setMasterFormData((prevFormData: any) => ({
                  ...prevFormData,
                  location_id: rowData?.locationActivityId,
                  location_name: rowData?.locationTypeName,
                  })); 
        } else {
          setSelectedLocation([]);
        }
        } catch (error) {
        console.error("user response", error);
        }
    };

    // get MasterProcessList
    const getMasterProcessList = async (activity_type_id: string) => {
        console.log("handleFieldOnChange=>getMasterProcessList", activity_type_id);
        let rowData: any = [];
        try {
        if (
            _h.isNotEmpty(masterFormData.activity_type_id) ||
            _h.isNotEmpty(activity_type_id)
        ) {
            const response = await scheduleService.get_process_by_activity(
            _h.isNotEmpty(activity_type_id)
                ? activity_type_id
                : masterFormData.activity_type_id
            );
            console.log("getMasterProcessList=>response", response);
            if (response.status && response.statusCode === 200) {
            try {
                if (Array.isArray(response.data) && response.data?.length > 0) {
                rowData = response.data.map((element: any, index: number) => {
                    return {
                    slno: index + 1,
                    id: element.id,
                    name: element.name,
                    };
                });
                setProcessList(rowData);
                }
            } catch (error) {
                console.log(error);
                setProcessList([]);
            }
            } else {
            setProcessList([]);
            }
        } else {
            setProcessList([]);
        }
        } catch (error) {
        console.error("user response", error);
        }
        return rowData;
    };

    //handle onchange input fields
    const handleAddFieldOnChange = async (e: any) => {
        const { name, value } = e.target;
        console.log("handleFieldOnChange=>{ name, value }", { name, value });
        console.log("prev masterFormData", masterFormData);
        // if (name == "schedule_production_id") {
        //   let selectedProd = prodScheduleList.filter((prod) => {
        //     return prod.id == value;
        //   });
        //   console.log("handleFieldOnChange=>selectedProd", selectedProd);
        //   if (_h.arrayHasData(selectedProd) && _h.objectHasValue(selectedProd[0])) {
        //     let activityProcess: any = await getMasterProcessList(
        //       selectedProd[0]?.activity_type_id
        //     );
        //     console.log("handleFieldOnChange=>activityProcess", activityProcess);
        //     setProcessList(activityProcess);
        //     setMasterFormData((prevFormData: any) => ({
        //       ...prevFormData,
        //       activity_type_id: selectedProd[0]?.activity_type_id,
        //     }));
        //   }
        // }
        if (
        name == "schedule_production_id" &&
        _h.isNotEmpty(masterFormData?.process_id)
        ) {
        // get_locations({
        //     process_id: masterFormData?.process_id,
        //     production_id: value,
        // });
        }

        if (name == "activity_type_id") {
        if (_h.isNotEmpty(value)) {
            let selectedName = activityType.filter((row) => {
            return row.id == value;
            });
            setMasterFormData((prevFormData: any) => ({
            ...prevFormData,
            activity_type_name: selectedName[0]?.name,
            }));
            let activityProcess: any = await getMasterProcessList(value);
            setProcessList(activityProcess);
            get_locations(value)
        }
        }

        if (name == "process_id") {
        let selectedName = processList.filter((row) => {
            return row.id == value;
        });
        setMasterFormData((prevFormData: any) => ({
            ...prevFormData,
            process_name: selectedName[0]?.name,
        }));
        // get_locations({
        //     process_id: value,
        //     production_id: masterFormData?.schedule_production_id,
        // });
        }

        if (name === "area_id") {
          // areaListing(value)
          if (_h.isNotEmpty(value)) {
              let selectedName:any = areaData?.filter((row:any) => {
              return row.id == value;
              });
              setMasterFormData((prevFormData: any) => ({
              ...prevFormData,
              area_name: selectedName[0]?.name,
              }));
          }
        }

        if (name == "location_id") {
        if (_h.isNotEmpty(value)) {
            let selectedName = locationList.filter((row) => {
            return row.id == value;
            });
            setMasterFormData((prevFormData: any) => ({
            ...prevFormData,
            location_name: selectedName[0]?.name,
            }));
        }
        }
        if (name == "work_shift_id") {
        if (_h.isNotEmpty(value)) {
            let selectedName = workShiftList.filter((row) => {
            return row.id == value;
            });
            setMasterFormData((prevFormData: any) => ({
            ...prevFormData,
            work_shift_name: selectedName[0]?.name,
            }));
        }
        }

        if (name == "user_master_id") {
        if (_h.isNotEmpty(value)) {
            let selectedName = usersList.filter((row) => {
            return row.id == value;
            });
            setMasterFormData((prevFormData: any) => ({
            ...prevFormData,
            user_name: selectedName[0]?.name,
            }));
        }
        }

        if (name == "start_date_time" && _h.isNotEmpty(value)) {
        setMasterFormData((prevFormData: any) => ({
            ...prevFormData,
            start_date_time: moment(value, "YYYY-MM-DD").format(
            "YYYY-MM-DD HH:mm:ss"
            ),
            start_date_time_copy: moment(value, "YYYY-MM-DD").format(
            "YYYY-MM-DD"
            ),
        }));
        } else if (name == "end_date_time" && _h.isNotEmpty(value)) {
        setMasterFormData((prevFormData: any) => ({
            ...prevFormData,
            end_date_time: moment(value, "YYYY-MM-DDTHH:mm").format(
            "DD-MM-YYYY - HH:mm:ss"
            ),
        }));
        } else {
        setMasterFormData((prevFormData: any) => ({
            ...prevFormData,
            [name]: value,
        }));
        }

        console.log("handleFieldOnChange=>masterFormData", masterFormData);
    };

    //handle area validation starts here
    const handleFormValidation = () => {
        if (masterFormData.id) {
        if (!handleCommonFormValidation()) {
            return;
        }
        }

        // const errors: Errors = {};
        // if (!masterFormData.activity_type_id) {
        //   errors.activity_type_id = "Please select an activity type";
        // }
        // if (!masterFormData.schedule_production_id) {
        // errors.schedule_production_id = "Please select an activity type";
        // }
        // if (!masterFormData.start_date_time) {
        // errors.start_date_time = "Please select an activity type";
        // }
        // if (!masterFormData.end_date_time) {
        // errors.end_date_time = "Please select an activity type";
        // }
        // if (!masterFormData.process_id) {
        //   errors.process_id = "Please select an activity type";
        // }
        // if (!masterFormData.user_master_id) {
        //   errors.user_master_id = "Please select an activity type";
        // }
        // if (
        // _h.isNotEmpty(masterFormData.start_date_time) &&
        // _h.isNotEmpty(masterFormData.end_date_time))
        // ) {
        // let fromEpoch = new Date(masterFormData.start_date_time).valueOf();
        // let toEpoch = new Date(masterFormData.end_date_time).valueOf();
        // if (fromEpoch >= toEpoch) {
        //     errors.start_date_time = "Start date should be less than End date";
        //     errors.end_date_time = "End date should be greater than Start date";
        // }
        // }

        if (addedOperators.length == 0) {
        // errors.reason = "Please enter reason for edit";
        // added_operators
        setToastType("error");
        setToastMessage("Please select atleast one operator");
        setShowToast(true);
        setShowAddAreaAlert(false);
        return;
        }

        // if (Object.keys(errors).length > 0) {
        //   setErrors(errors);
        //   return;
        // }
        setShowAddAreaAlert(true);
        setErrors({});
    };

    const handleReAssignFormSubmit = async () => { 
      const formattedStartDate = reassignFormData?.start_date_time ? moment(reassignFormData.start_date_time, "DD-MM-YYYY - HH:mm:ss").format("YYYY-MM-DD HH:mm:ss") : "";
      const formattedEndDate = reassignFormData?.end_date_time ? moment(reassignFormData.end_date_time, "DD-MM-YYYY - HH:mm:ss").format("YYYY-MM-DD 23:59:59") : "";
      const formattedNewDate = reassignFormData?.new_start_time ? moment(reassignFormData.new_start_time, "DD-MM-YYYY - HH:mm:ss").format("YYYY-MM-DD HH:mm:ss") : "";
 
      const dataToSend = {
        start_date_time: formattedStartDate,
        end_date_time: formattedEndDate,
        new_start_time: formattedNewDate,
        user_master_id: reassignFormData.user_master_id,
        assignUser_id: reassignFormData.assignUser_id,
        operator_ids: selectedOperatorIds,
        interface_name: "Reassign Operator",
      }
      console.log(dataToSend, "dataToSend")
      try {
          const response = await scheduleService.reassignScheduleOperator(dataToSend);
          if (response.status && response.statusCode === 200) {
            setToastType("success");
            setToastMessage(response.message);
            getOperatorScheduleList();
            handleFormClose();
            setReAssigSubmitAlert(false);
          } else {
            setToastType("error");
            setToastMessage(response.message);
          }
          setShowToast(true);
        } catch (error) {
          console.error(error);
        }
    
        return true;
      };
    
    const assignFieldOnSubmit = () => {
      const errors: Errors = {};
      if (!masterFormData?.work_shift_id) {
        errors.work_shift_id = "Please select a work shift";
      }
      if (!masterFormData.user_master_id) {
        errors.user_master_id = "Please select an user";
      }
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }
      setAssigSubmitAlert(true)
      setErrors({});
    }

    const reAssignFieldOnSubmit = () => {
        const errors: Errors = {};
        if (!reassignFormData.start_date_time) {
          errors.start_date_time = "Please select a start date time";
        }
        if (!reassignFormData.end_date_time) {
          errors.end_date_time = "Please select an end date time";
        }
        if (!reassignFormData.user_master_id) {
          errors.user_master_id = "Please select an operator";
        }
        if (!reassignFormData.assignUser_id) {
          errors.assignUser_id = "Please select a reassign operator";
        }
        if (reassignFormData.user_master_id && reassignFormData.user_master_id == reassignFormData.assignUser_id) {
          errors.assignUser_id = "Please select a different operator";
        }
        if (
          _h.isNotEmpty(reassignFormData.start_date_time) &&
          _h.isNotEmpty(reassignFormData.end_date_time)
        ) {
          let fromEpoch = new Date(reassignFormData.start_date_time).valueOf();
          let toEpoch = new Date(reassignFormData.end_date_time).valueOf();
          if (fromEpoch >= toEpoch) {
            errors.start_date_time = "Start date should be less than End date";
            errors.end_date_time = "End date should be greater than Start date";
          }
        }
        if (Object.keys(errors).length > 0) {
          console.log(errors, "errors")
          setErrors(errors);
          return false;
        }
        setErrors({});
        setReAssigSubmitAlert(true)
    }

    const updateSelectedOperatorTableData = () => {
        if (!handleCommonFormValidation()) {
          return;
        }
        console.log(masterFormData, "masterFormData")
        addedOperators.unshift(masterFormData);
        let rowData: any = addedOperators.map((element: any, index: number) => {
          return {
            ...element,
            slno: index + 1,
          };
        });
        setAddedOperators(rowData);
        setMasterFormData((prevFormData: any) => ({
          ...prevFormData,
          activity_type_id: "",
          process_id: "",
          area_id: "",
          location_id: "",
          area_name: "",
          location_name: "",
          work_shift_id: "",
          user_master_id: "",
          start_date_time: "",
          plate_count: 0,
        }));
        setProcessList([])
        setLocationList([])
      };

     //handle onchange input fields
    const handleReassignFieldOnChange = async (e: any) => {
        const { name, value } = e.target;
        console.log("handleReassignFieldOnChange=>{ name, value }", {
        name,
        value,
        });
        let tempReassignFormData: any = reassignFormData;

        if (
        ["start_date_time", "end_date_time", "new_start_time"].includes(name) &&
        _h.isNotEmpty(value)
        ) {
        tempReassignFormData[name] = moment(value, "YYYY-MM-DD").format(
            "DD-MM-YYYY - HH:mm:ss"
        );
        } else {
        tempReassignFormData[name] = value;
        }
        if (
        ["user_master_id", "start_date_time", "end_date_time"].includes(name) &&
        _h.isNotEmpty(tempReassignFormData?.user_master_id) &&
        _h.isNotEmpty(tempReassignFormData?.start_date_time) &&
        _h.isNotEmpty(tempReassignFormData?.end_date_time)
        ) {
        setReassignOperatorSchedules([]);
        const response = await scheduleService.getOperatorSchedules({
            sort: "id",
            order: "desc",
            user_master_id: tempReassignFormData?.user_master_id,
            start_date_time: moment(
            tempReassignFormData.start_date_time,
            "DD-MM-YYYY - HH:mm:ss"
            ).format("YYYY-MM-DD HH:mm:ss"),
            end_date_time: moment(
            tempReassignFormData.end_date_time,
            "DD-MM-YYYY - HH:mm:ss"
            ).format("YYYY-MM-DD HH:mm:ss"),
        });
        if (response.status && response.statusCode === 200) {
            try {
            if (Array.isArray(response.data) && response.data?.length > 0) {
                let rowData: any = response.data.map(
                (element: any, index: number) => {
                    return {
                    slno: index + 1,
                    id: element.id,
                    start_date_time: element?.start_date_time
                        ? moment(
                            element?.start_date_time,
                            "YYYY-MM-DD HH:mm:ss"
                        ).format("DD-MM-YYYY")
                        : "-",
                    end_date_time: element?.end_date_time
                        ? moment(
                            element?.end_date_time,
                            "YYYY-MM-DD HH:mm:ss"
                        ).format("DD-MM-YYYY")
                        : "-",
                    production_schedule_name: element?.production_schedule_name,
                    product_name: element?.product_name,
                    production_type: element?.production_type,
                    user_first_name: element?.user_first_name,
                    user_last_name: element?.user_last_name,
                    operator_name: (
                        (element?.user_first_name).trim() +
                        " " +
                        (element?.user_last_name).trim()
                    ).trim(),
                    process_name: element?.process_name,
                    activity_type_name: element?.activity_type_name,
                    area_name: element?.area_name,
                    location_name: element?.location_name,
                    work_shift_name: element?.work_shift_name,
                    };
                }
                );
                setReassignOperatorSchedules(rowData);
            }
            } catch (error) {
            console.log(error);
            setReassignOperatorSchedules([]);
            }
        }
        }
        // setReassignFormData(tempReassignFormData);
        setReassignFormData((prevFormData: any) => ({
        ...prevFormData,
        [name]: tempReassignFormData[name],
        }));
        console.log(
        "handleReassignFieldOnChange=>tempReassignFormData",
        tempReassignFormData
        );
        console.log(
        "handleReassignFieldOnChange=>reassignFormData",
        reassignFormData
        );
    };

    const handleAssignOperatorClick = (slno: string[]) => {
        const filteredPlateDetails = tableData.filter((item : any) => slno.includes(item.slno));
        const scheduledOperatorIds: any = filteredPlateDetails.map((item: any) => item.id)

        const updatedPlateDetails = tableData
        .filter((item: any) => slno.includes(item.slno))
        .map((item: any, index: number) => ({
            ...item,
            slno: index + 1 // Update slno with the new value
        }));
        const anyHasAssigne = updatedPlateDetails.some(item => item.operator_name && item.operator_name !== " ");

        if(anyHasAssigne) {
          setToastMessage("Operator already assigned.");
          setToastType("error")
          setShowToast(true)
        } else {
          if(slno.length > 0 ) {
            setScheduledOperatorIds(scheduledOperatorIds)
          } else {
            setScheduledOperatorIds([])
          }
          setShowAssignModal(true)
          setModalName("Assign Operator")
        }
    }

    const handleReassignActionClick = (slno: string[]) => {
      const filteredPlateDetailsIds : any = reassignOperatorSchedules
          .filter((item: any) => slno.includes(item.slno))
          .map((item: any) => Number(item.id));
      setSelectedOperatorIds(filteredPlateDetailsIds)
    }

    // handle assign form submit 
    const handleAssignFormSubmit = async () => {

      const dataToSend = scheduledOperatorIds?.map(id => ({
        "schedule_operator_id": id,
        "user_master_id": masterFormData.user_master_id,
        "interface_name" : modalName
      }));

      try {
        const response = await scheduleService.assignOperator(
          dataToSend
        );
        if (response.status && response.statusCode === 200) {
            setToastType("success");
            setToastMessage(response.message);
            getOperatorScheduleList();
            setAssigSubmitAlert(false)
            setActionClearAll(true)
            handleFormClose();
        } else {
            setToastType("error");
            setToastMessage(response.message);
        }
        setShowAddAreaAlert(false);
        setShowToast(true);
        } catch (error) {
        console.error(error);
        }
    }

    //handle add area submit api call
    const handleFormSubmit = async () => {
        // const dataToSend = {
        //   id: masterFormData.id,
        //   activity_type_id: masterFormData.activity_type_id,
        //   interface_name: "Schedule Operator",
        //   reason: masterFormData.reason,
        //   schedule_production_id: masterFormData.schedule_production_id,
        //   start_date_time: _h.isNotEmpty(masterFormData.start_date_time)
        //     ? moment(
        //         masterFormData.start_date_time,
        //         "DD-MM-YYYY - HH:mm:ss"
        //       ).format("YYYY-MM-DD HH:mm:ss")
        //     : "",
        //   end_date_time: _h.isNotEmpty(masterFormData.end_date_time)
        //     ? moment(masterFormData.end_date_time, "DD-MM-YYYY - HH:mm:ss").format(
        //         "YYYY-MM-DD HH:mm:ss"
        //       )
        //     : "",
        //   process_id: masterFormData.process_id,
        //   user_master_id: masterFormData.user_master_id,
        // };
        let dataToSend: any = [];
        Object.values(addedOperators).forEach((val: any) => {
        // val["start_date_time"] = _h.isNotEmpty(masterFormData.start_date_time)
        //     ? moment(
        //         masterFormData.start_date_time,
        //         "DD-MM-YYYY - HH:mm:ss"
        //     ).format("YYYY-MM-DD HH:mm:ss")
        //     : "";
        // val["end_date_time"] = _h.isNotEmpty(masterFormData.end_date_time)
        //     ? moment(masterFormData.end_date_time, "DD-MM-YYYY - HH:mm:ss").format(
        //         "YYYY-MM-DD HH:mm:ss"
        //     )
        //     : "";
        val["interface_name"] = "Assign Operator";
        dataToSend.push(val);
        });

        try {
        const response = await scheduleService.createScheduleOperatorBulk(
            dataToSend
        );
        if (response.status && response.statusCode === 200) {
            setToastType("success");
            setToastMessage(response.message);
            getOperatorScheduleList();
            handleFormClose();
        } else {
            setToastType("error");
            setToastMessage(response.message);
        }
        setShowAddAreaAlert(false);
        setShowToast(true);
        } catch (error) {
        console.error(error);
        }
    };




    //handle user details for submit handling
    useEffect(() => {
        var storedData = localStorage.getItem("userDetails");
        if (storedData) {
            var storedObject = JSON.parse(storedData);
            getPermissionByRole(storedObject[0].role_master_id);
        }

        getOperatorScheduleList()
        getProductionSchedules()
        get_work_shifts()
        getActivityTypeList()
        getMasterUserList()
        getAreaList()

        setUseEffectRequired(false);
    }, [useEffectRequired]);


  return (
    <div>
        <HeaderSection />
        <PageHeader pageHeader={t("scheduleOperator.pageTitle")} />
        <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
        />

        <div className="me-3" style={{ marginTop: "10px" }}>
            <div className=" mx-3 pe-2" style={{ height: "45vh" }}>
            <CustomTable
                tableHead={t("scheduleOperator.tableHead")}
                data={tableData}
                columns={columns}
                // isEditable={permission?.includes("edit_operator_schedule")}
                isEditable={false}
                totalElements={totalElements}
                isActionVisible={true}
                isViewVisible={false}
                onViewClick={handleViewClick}
                isSeachable={false}
                // addButton={permission?.includes("add_operator_schedule")}
                // addButtonText='Assign Operator'
                // onAddClick={handleAssignOperatorClick}
                actionCheckOrangeButton={permission?.includes("add_operator_schedule")}
                actionCheckOrangeBtnText='Assign Operator'
                onActionCheckOrangeButtonClick={handleAssignOperatorClick}
                onFilterClick={handleFilter}
                onSortClick={handleSort}
                isToggleStatus={false}
                onStatusChange={handleStatusChange}
                isQrDownloadable={false}
                onEditClick={handleEditClick}
                showQrGenerateButton={false}
                showBlueBtn={permission?.includes("add_operator_schedule")}
                blueBtnText={t("scheduleOperator.add")}
                onBlueBtnClick={handleAddClick}
                showToDateFilter={true}
                showFromDateFilter={true}
                dateType='date'
                discardButton={permission?.includes("add_operator_schedule")}
                discardButtonText={t("scheduleOperator.reassign_operator")}
                onDiscardClick={handleReassignOperator}
                // actionCheckOrangeButton2={permission?.includes("add_operator_schedule")}
                // actionCheckOrangeBtnText2={t("scheduleOperator.reassign_operator")}
                // onActionCheckOrangeButtonClick2={handleReassignOperator}
                showFullFilters={true}
                ActivityType={activityType}
                process={processList}
                assignee={usersList}
                areaDropList={areaData}
                prodScheduleList={prodScheduleList}
                clearAll={actionClearAll}
            />
            </div>
            <div>
            <Pagination
                page={page}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
            />
            </div>
        </div>

        {/*modal for add Operator schedule */}
      <Modal
        className="top-right-modal-md"
        centered={false}
        show={showAddModal}
        // size='lg'
      >
        <div>
          <div className="modalHead">
            <p className="modalHeadText ms-3 pt-3 p-2">{modalName}</p>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleFormClose()}
              className="p-2 mx-3 my-2 "
            >
              <TfiClose fill="#FFFFFF" />
            </div>
          </div>

          <div className="selectWrap-new">
            <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleOperator.activityType")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <select
                id="activity_type_id"
                value={masterFormData?.activity_type_id}
                name="activity_type_id"
                onChange={handleAddFieldOnChange}
                // disabled={true}
              >
                <option value="">Select</option>
                {activityType.map((val) => (
                  <option key={val.id} value={val.id}>
                    {val.name}
                  </option>
                ))}
              </select>
              {errors.activity_type_id && (
                <p className="errorText">{errors.activity_type_id}</p>
              )}
            </div>
            
            <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleOperator.area")}
                {/* <span style={{ color: "red" }}>*</span> */}
              </label>
              <br />
              <select
                id="area_id"
                // value={masterFormData?.area_id}
                name="area_id"
                onChange={handleAddFieldOnChange}
              >
                <option value="">Select</option>
                {areaData.map((val:any) => (
                  <option key={val.id} value={val.id}>
                    {val.name}
                  </option>
                ))}
              </select>
              {/* {errors.location_id && (
                <p className="errorText">{errors.location_id}</p>
              )} */}
            </div>

            <div className="form-group my-1 mx-3 px-2" style={{"visibility": "hidden"}}>
              <label>
                {t("scheduleOperator.process")}
                {/* <span style={{ color: "red" }}>*</span> */}
              </label>
              <br />
              <select
                id="process_id"
                value={masterFormData?.process_id}
                name="process_id"
                onChange={handleAddFieldOnChange}
              >
                <option value="">Select</option>
                {_h.arrayHasData(processList) &&
                  processList.map((val) => (
                    <option key={val.id} value={val.id}>
                      {val.name}
                    </option>
                  ))}
              </select>
              {errors.process_id && (
                <p className="errorText">{errors.process_id}</p>
              )}
            </div>
            {/* <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleOperator.location")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <select
                id="location_id"
                value={masterFormData?.location_id}
                name="location_id"
                onChange={handleAddFieldOnChange}
              >
                <option value="">Select</option>
                {locationList.map((val) => (
                  <option key={val.id} value={val.id}>
                    {val.name}
                  </option>
                ))}
              </select>
              {errors.location_id && (
                <p className="errorText">{errors.location_id}</p>
              )}
            </div> */}
          </div>

          <div className="selectWrap-new">
             <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleOperator.location")}
                {masterFormData?.area_id && (<span style={{ color: "red" }}>*</span>)}
              </label>
              <br />
              {/* <input
                type='text'
                value={masterFormData?.location_name}
                name="location_id"
                onChange={handleAddFieldOnChange}
                disabled={true}
              /> */}
              <select
                id="location_id"
                value={masterFormData?.location_id}
                name="location_id"
                onChange={handleAddFieldOnChange}
              >
                <option value="">Select</option>
                {locationList?.map((val: any) => (
                  <option key={val.id} value={val.id}>
                    {val.name}
                  </option>
                ))}
              </select>
              {errors.location_id && (
                <p className="errorText">{errors.location_id}</p>
              )}
            </div>
            <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleOperator.work_shift")}
                {/* <span style={{ color: "red" }}>*</span> */}
              </label>
              <br />
              <select
                id="work_shift_id"
                value={masterFormData?.work_shift_id}
                name="work_shift_id"
                onChange={handleAddFieldOnChange}
              >
                <option value="">Select</option>
                {workShiftList.map((val) => (
                  <option key={val.id} value={val.id}>
                    {val.name}
                  </option>
                ))}
              </select>
              {errors.work_shift_id && (
                <p className="errorText">{errors.work_shift_id}</p>
              )}
            </div>
            <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleOperator.operator")}
                {/* <span style={{ color: "red" }}>*</span> */}
              </label>
              <br />
              <select
                id="user_master_id"
                value={masterFormData?.user_master_id}
                name="user_master_id"
                onChange={handleAddFieldOnChange}
              >
                <option value="">Select</option>
                {usersList.map((val) => (
                  <option key={val.id} value={val.id}>
                    {val.name}
                  </option>
                ))}
              </select>
              {errors.user_master_id && (
                <p className="errorText">{errors.user_master_id}</p>
              )}
            </div>
          </div>

          <div className="selectWrap-new">
          <div className="form-group mx-3 px-2" style={{"width":"205px"}}>
              <label>
                {t("scheduleOperator.start_date_time")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                type="date"
                name="start_date_time"
                id="start_date_time"
                // value={
                //   _h.isNotEmpty(masterFormData.start_date_time)
                //     ? moment(
                //         masterFormData.start_date_time,
                //         "DD-MM-YYYY - HH:mm:ss"
                //       ).format("YYYY-MM-DDTHH:mm")
                //     : ""
                // }
                value={moment(masterFormData?.start_date_time).format("YYYY-MM-DD")}
                onChange={handleAddFieldOnChange}
                min={moment(dateTime).format("YYYY-MM-DD")}
                className="filter-input"
                onKeyDown={(e) => {
                  const allowedKeys = [
                    "Tab",
                    "Enter",
                    "Escape",
                  ];
                  if (!allowedKeys.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                onPaste={(e) => e.preventDefault()}


                // value={
                //   _h.isNotEmpty(reassignFormData.end_date_time)
                //     ? moment(
                //         reassignFormData.end_date_time,
                //         "DD-MM-YYYY - HH:mm:ss"
                //       ).format("YYYY-MM-DDTHH:mm")
                //     : ""
                // }

              />
              {errors.start_date_time && (
                <p className="errorText">{errors.start_date_time}</p>
              )}
            </div>

            <div className="form-group my-1 mx-3 px-2">
                <label>
                  {t("scheduleFrequency.quantity")}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input
                  id="plate_count"
                  value={masterFormData?.plate_count}
                  name="plate_count"
                  type='number'
                  min={1}
                  onChange={handleAddFieldOnChange}
                  onKeyDown={(e) => {
                    if (e.key === '-' || e.key === 'e' || e.key === '+') {
                      e.preventDefault();
                    }
                  }}
                />
                {errors.plate_count && (
                  <p className="errorText">{errors.plate_count}</p>
                )}
            </div>

            {!masterFormData.id && (
                <div className="form-group my-1 mx-3 px-2">
                    <label></label>
                    <br />
                    <button
                    onClick={() => updateSelectedOperatorTableData()}
                    className="squareOrangeBtn3"
                    >
                    {t("buttons.add")}
                    </button>
                </div>
                )}
            {masterFormData.id && (
              <div className="form-group my-1 mx-3 px-2">
                <label htmlFor="" className="ReasonLabel">
                  {t("userManagement.reason")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input
                  id="reason"
                  value={masterFormData.reason}
                  name="reason"
                  type="text"
                  placeholder={t("userManagement.reason")}
                  className="reasonClass"
                  onChange={handleAddFieldOnChange}
                />
                {errors.reason && <p className="errorText">{errors.reason}</p>}
              </div>
            )}
          </div>

          {!masterFormData.id && (
            <div className="me-3" style={{ marginTop: "1px" }}>
              <div className=" mx-3 pe-2" style={{ height: "25vh" }}>
                <CustomTable
                  tableHead={t("scheduleOperator.taskAssigned")}
                  data={addedOperators}
                  columns={addedOperatorsColumns}
                  isEditable={false}
                  totalElements={addedOperators.length}
                  isActionVisible={false}
                  isViewVisible={false}
                  // onViewClick={handleViewClick}
                  isSeachable={false}
                  // addButton={permission?.includes("add_production_schedule")}
                  // addButtonText={t("scheduleProduction.add")}
                  // onFilterClick={handleFilter}
                  // onSortClick={handleSort}
                  // onAddClick={handleAddClick}
                  isToggleStatus={false}
                  // onStatusChange={handleStatusChange}
                  isQrDownloadable={false}
                  // onEditClick={handleEditClick}
                  showQrGenerateButton={false}
                  actionButtons={[
                    {
                      thLabel: t("scheduleOperator.remove"),
                      buttonLabel: t("scheduleOperator.remove"),
                      handleAction: (row: any) => {
                        console.log("customRowButton=>handleAction", row);
                        // handleQRGenerate(row);
                        setAddedOperators((prevOperators) => 
                            prevOperators.filter((operator) => operator.slno !== row.slno)
                        );
                      },
                    },
                  ]}
                />
              </div>
            </div>
          )}

          <div className="bottomArea">
            <button
              onClick={() => handleFormClose()}
              className="modalCancelBtn"
            >
              {t("buttons.cancel")}
            </button>
            <button
              onClick={() => handleFormValidation()}
              className="squareOrangeBtn3"
            >
              {t("buttons.submit")}
            </button>
          </div>
        </div>
      </Modal>

      {/*modal for reassign Operator schedule */}
      <Modal
        className="top-right-modal-md"
        centered={false}
        show={showReassignModal}
        // size='lg'
      >
        <div>
          <div className="modalHead">
            <p className="modalHeadText ms-3 pt-3 p-2">{modalName}</p>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleFormClose()}
              className="p-2 mx-3 my-2 "
            >
              <TfiClose fill="#FFFFFF" />
            </div>
          </div>

          <div className="selectWrap-new">
            <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleOperator.operator")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <select
                id="user_master_id"
                value={reassignFormData?.user_master_id}
                name="user_master_id"
                onChange={handleReassignFieldOnChange}
              >
                <option value="">select</option>
                {usersList.map((val) => (
                  <option key={val.id} value={val.id}>
                    {val.name}
                  </option>
                ))}
              </select>
              {errors.user_master_id && (
                <p className="errorText">{errors.user_master_id}</p>
              )}
            </div>
            <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleOperator.fromDate")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                type="date"
                name="start_date_time"
                id="start_date_time"
                value={
                  _h.isNotEmpty(reassignFormData.start_date_time)
                    ? moment(
                        reassignFormData.start_date_time,
                        "DD-MM-YYYY - HH:mm:ss"
                      ).format("YYYY-MM-DD")
                    : ""
                }
                onChange={handleReassignFieldOnChange}
                className="filter-input"
                onKeyDown={(e) => {
                  const allowedKeys = [
                    "Tab",
                    "Enter",
                    "Escape",
                  ];
                  if (!allowedKeys.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                onPaste={(e) => e.preventDefault()}
              />
              {errors.start_date_time && (
                <p className="errorText">{errors.start_date_time}</p>
              )}
            </div>
            <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleOperator.toDate")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                type="date"
                name="end_date_time"
                id="end_date_time"
                value={
                  _h.isNotEmpty(reassignFormData.end_date_time)
                    ? moment(
                        reassignFormData.end_date_time,
                        "DD-MM-YYYY - HH:mm:ss"
                      ).format("YYYY-MM-DD")
                    : ""
                }
                onChange={handleReassignFieldOnChange}
                className="filter-input"
                onKeyDown={(e) => {
                  const allowedKeys = [
                    "Tab",
                    "Enter",
                    "Escape",
                  ];
                  if (!allowedKeys.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                onPaste={(e) => e.preventDefault()}
              />
              {errors.end_date_time && (
                <p className="errorText">{errors.end_date_time}</p>
              )}
            </div>
          </div>

          <div className="selectWrap-new">
            <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleOperator.assignUser")}
                <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <select
                id="assignUser_id"
                value={reassignFormData?.assignUser_id}
                name="assignUser_id"
                onChange={handleReassignFieldOnChange}
              >
                <option value="">select</option>
                {usersList.map((val) => (
                  <option key={val.id} value={val.id}>
                    {val.name}
                  </option>
                ))}
              </select>
              {errors.assignUser_id && (
                <p className="errorText">{errors.assignUser_id}</p>
              )}
            </div>
            <div className="form-group my-1 mx-3 px-2">
              <label>
                {t("scheduleOperator.newStartDate")}
                {/* <span style={{ color: "red" }}>*</span> */}
              </label>
              <br />
              <input
                type="date"
                name="new_start_time"
                id="new_start_time"
                value={
                  _h.isNotEmpty(reassignFormData.new_start_time)
                    ? moment(
                        reassignFormData.new_start_time,
                        "DD-MM-YYYY - HH:mm:ss"
                      ).format("YYYY-MM-DD")
                    : ""
                }
                onChange={handleReassignFieldOnChange}
                className="filter-input"
                onKeyDown={(e) => {
                  const allowedKeys = [
                    "Tab",
                    "Enter",
                    "Escape",
                  ];
                  if (!allowedKeys.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                onPaste={(e) => e.preventDefault()}
              />
              {errors.newStartDate && (
                <p className="errorText">{errors.newStartDate}</p>
              )}
            </div>
          </div>

          <div className="me-3" style={{ marginTop: "10px" }}>
            <div className=" mx-3 pe-2" style={{ height: "35vh" }}>
              <CustomTable
                tableHead={t("scheduleOperator.operatorSchedules")}
                data={reassignOperatorSchedules}
                columns={reassignOperatorsColumns}
                isEditable={false}
                totalElements={reassignOperatorSchedules.length}
                isActionVisible={true}
                isViewVisible={false}
                isSeachable={false}
                isToggleStatus={false}
                isQrDownloadable={false}
                showQrGenerateButton={false}
                onActionClick={handleReassignActionClick}
              />
            </div>
          </div>

          <div className="bottomArea">
            <button
              onClick={() => handleFormClose()}
              className="modalCancelBtn"
            >
              {t("buttons.cancel")}
            </button>
            <button
              onClick={() => reAssignFieldOnSubmit()}
              disabled={selectedOperatorIds.length <= 0}
              className={`squareOrangeBtn3 ${selectedOperatorIds.length <= 0 && "squareOrangeBtn3Disabled"}`}
              // className="squareOrangeBtn3 squareOrangeBtn3Disabled"
            >
              {t("buttons.submit")}
            </button>
          </div>
        </div>
      </Modal>

      {/* assign operator modal */}
      <Modal
        className="top-right-modal-md"
        centered={false}
        show={showAssignModal}
      >
        <div>
            <div className="modalHead">
                <p className="modalHeadText ms-3 pt-3 p-2">{modalName}</p>
                <div
                style={{ cursor: "pointer" }}
                onClick={() => handleFormClose()}
                className="p-2 mx-3 my-2 "
                >
                <TfiClose fill="#FFFFFF" />
                </div>
            </div>

            <div className='selectWrap-new'>
              <div className="form-group my-1 mx-3 px-2">
                <label>
                  {t("scheduleOperator.work_shift")}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <select
                  id="work_shift_id"
                  value={masterFormData?.work_shift_id}
                  name="work_shift_id"
                  onChange={handleAddFieldOnChange}
                >
                  <option value="">Select</option>
                  {workShiftList.map((val) => (
                    <option key={val.id} value={val.id}>
                      {val.name}
                    </option>
                  ))}
                </select>
                {errors.work_shift_id && (
                  <p className="errorText">{errors.work_shift_id}</p>
                )}
              </div>
              <div className="form-group my-1 mx-3 px-2">
                <label>
                  {t("scheduleOperator.users")}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <select
                  id="user_master_id"
                  value={masterFormData?.user_master_id}
                  name="user_master_id"
                  onChange={handleAddFieldOnChange}
                >
                  <option value="">Select</option>
                  {usersList.map((val) => (
                    <option key={val.id} value={val.id}>
                      {val.name}
                    </option>
                  ))}
                </select>
                {errors.user_master_id && (
                  <p className="errorText">{errors.user_master_id}</p>
                )}
              </div>
            </div>


            <div className="bottomArea">
                <button
                onClick={() => handleFormClose()}
                className="modalCancelBtn"
                >
                {t("buttons.cancel")}
                </button>
                <button
                onClick={() => assignFieldOnSubmit()}
                className="squareOrangeBtn3"
                >
                {t("buttons.submit")}
                </button>
            </div>
        </div>
      </Modal>

      {/* Alert modal for Add/edit Operator schedule */}
      <AlertModal
        show={showAddAreaAlert}
        onHide={() => setShowAddAreaAlert(false)}
        title="Alert"
        message={modalName}
        onConfirm={handleFormSubmit}
      />

      {/* Alert modal for Add/edit Operator assign */}
      <AlertModal
        show={assigSubmitAlert}
        onHide={() => setAssigSubmitAlert(false)}
        title="Alert"
        message={modalName}
        onConfirm={handleAssignFormSubmit}
      />

      {/* Alert modal for reassign */}
      <AlertModal
        show={reAssigSubmitAlert}
        onHide={() => setReAssigSubmitAlert(false)}
        title="Alert"
        message={modalName}
        onConfirm={handleReAssignFormSubmit}
      />

    </div>
  )
}

export default ScheduleOperatorV2