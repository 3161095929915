

//table columns for lab Report
export const columns: TableColumn[] = [
    {
        Header: "Sl.No",
        accessor: "slno",
    },
    {
        Header: "tableCommon.batch_code",
        accessor: "batch_id",
    },
    {
        Header: "tableCommon.seriel_number",
        accessor: "plate_serial_number",
    },
    {
        Header: "Colony Count",
        accessor: "colony_count",
    },
    {
        Header: "Plate Activity Type",
        accessor: "plate_type",
    },
    {
        Header: "Lab Approved Date",
        accessor: "lab_approved_date",
    },
    {
        Header: "Lab Test Done By",
        accessor: "approved_by",
    },
    {
        Header: "Status",
        accessor: "status",
    },
  ];

export const columnsNew: TableColumn[] = [
    {
        Header: "Sl.No",
        accessor: "slno",
    },
    {
        Header: "tableCommon.seriel_number",
        accessor: "serial_number",
    },
    {
        Header: "tableCommon.batch_code",
        accessor: "batch_code",
    },
    {
        Header: "Lot Code",
        accessor: "lot_code",
    },
    {
        Header: "scheduleFrequency.tableColumns.activity_type_name",
        accessor: "activity",
    },
    {
        Header: "scheduleProduction.tableColumns.area_name",
        accessor: "area_name",
    },
    {
        Header: "Location",
        accessor: "location",
    },
    {
        Header: "Test Tube Serial No",
        accessor: "test_tube_serial_no",
    },
   
    {
        Header: "Equipment Code",
        accessor: "equipment_code",
    },
    {
        Header: "Activity Started By",
        accessor: "sampling_started_by",
    },
    {
        Header: "Activity Started Date",
        accessor: "sampling_started_date",
    },
    {
        Header: "Activity Ended By",
        accessor: "sampling_ended_by",
    },
    {
        Header: "Activity Ended Date",
        accessor: "sampling_ended_date",
    },
    {
        Header: "Activity Duration",
        accessor: "sampling_duration",
    },
    {
        Header: "Monitored User",
        accessor: "monitored_user",
    },
    {
        Header: "Monitored Location",
        accessor: "monitored_location",
    },
    {
        Header: "First Incubation Start By",
        accessor: "first_incubation_start_by",
    },
    {
        Header: "First Incubation Start Date",
        accessor: "first_incubation_start_date",
    },
    {
        Header: "First Incubation End By",
        accessor: "first_incubation_end_by",
    },
    {
        Header: "First Incubation End Date",
        accessor: "first_incubation_end_date",
    },
    {
        Header: "First Incubation Duration",
        accessor: "first_incubation_duration",
    },
    {
        Header: "Second Incubation Start By",
        accessor: "second_incubation_start_by",
    },
    {
        Header: "Second Incubation Start Date",
        accessor: "second_incubation_start_date",
    },
    {
        Header: "Second Incubation End By",
        accessor: "second_incubation_end_by",
    },
    {
        Header: "Second Incubation End Date",
        accessor: "second_incubation_end_date",
    },
    {
        Header: "Second Incubation Duration",
        accessor: "second_incubation_duration",
    },
    {
        Header: "Observer 1 Name",
        accessor: "observer1_name",
    },
    {
        Header: "Count 1",
        accessor: "count1",
    },
    {
        Header: "Observer 2 Name",
        accessor: "observer2_name",
    },
    {
        Header: "Count 2",
        accessor: "count2",
    },
    {
        Header: "Observer 3 Name",
        accessor: "observer3_name",
    },
    {
        Header: "Count 3",
        accessor: "count3",
    },
    {
        Header: "Colony Type",
        accessor: "colony_type",
    },
    {
        Header: "Organism Type",
        accessor: "organism_type",
    },
    {
        Header: "Genus",
        accessor: "genus",
    },
    {
        Header: "Species",
        accessor: "species",
    },
    {
        Header: "Strain",
        accessor: "strain",
    },
    {
        Header: "Plate Status",
        accessor: "plate_status",
    },
    {
        Header: "Approved by",
        accessor: "approve_id",
    },
];

  
  interface TableColumn {
    Header: string;
    accessor: string;
  }