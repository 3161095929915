import React,{useState, useEffect} from 'react'
import Select from 'react-select';
import { Column } from 'react-table';
import {userService as userRelatedService}  from '../../../service/userService';
import { userService as plateRelatedService } from '../../../service/plateServices';
import { color } from 'html2canvas/dist/types/css/types/color';
import { rawMaterials } from '../../../service/rawMaterials';
import { equipments } from '../../../service/equipments';
import moment from 'moment';




interface FilterSecProps {
    columns: Column[]; // Define the expected type of columns
    handleRoleChange: (columnName: string, value: any) => void;
    plateBatch?: any[];
    areaList?: any[];
    ReasonList?:any[];
    reportName?:any[];
    ActivityType?:any[];
    GradeTypes?:any[];
    prodScheduleList?: any[];
    areaGrade?:any[];
    assignee?:any[];
    locationCategory?: any[];
    areaDropList?: any[];
    process?:any[];
    locationList?:any[];
    plateSerialNumber?: any[];
    lotCodeNumber?: any[];
    areaListNumber?:any[];
    showBatchFilter?: boolean;
    showSerielNoFilter?: boolean;
    showLotCodeFilter?:boolean;
    showPlateStatus?: boolean;
    showPlateTypeFilter?: boolean;
    tableHead: string;
    showFromDateFilter?:boolean;
    showFromDateLabApproval?:boolean;
    showToDateLabApproval?:boolean;
    showInventoryFromDateFilter?:boolean;
    showToDateFilter?:boolean;
    dateType?: string;
    showYearFilter?:boolean;
}


const FilterSec: React.FC<FilterSecProps> = ({ 
      columns, 
      areaList,
      ReasonList,
      reportName,
      ActivityType,
      GradeTypes,
      prodScheduleList,
      areaGrade,
      assignee,
      locationCategory,
      areaDropList,
      process,
      locationList,
      handleRoleChange, 
      plateBatch, 
      plateSerialNumber, 
      lotCodeNumber,
      areaListNumber,
      showBatchFilter = true,
      showSerielNoFilter = true,
      showLotCodeFilter = true,
      showPlateStatus,
      showPlateTypeFilter,
      tableHead,
      showFromDateFilter,
      showFromDateLabApproval,
      showToDateLabApproval,
      showInventoryFromDateFilter,
      showToDateFilter,
      dateType = "date",
      showYearFilter 
    }) => {



    const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
    const formattedTime = new Date().toTimeString().split(' ')[0];  // HH:MM:SS

    const dateTime = `${currentDate} ${formattedTime}`;

     // end date should be 1 week ahead from currentDate
     const endDate = new Date();
     endDate.setDate(endDate.getDate() + 7);
     const formattedEndDate = endDate.toISOString().split('T')[0]; // YYYY-MM-DD
     const formattedEndTime = endDate.toTimeString().split(' ')[0]; // HH:MM:SS
     const endDateTime = `${formattedEndDate} ${formattedEndTime}`;


    const [departmentList, setDepartmentList] = useState<Array<any>>([]);
    const [materialType, setMaterialType] = useState<Array<any>>([]);
    const [userRoleList, setUserRoleList] = useState<Array<any>>([]);
    const [exceptionReasons, setExceptionReasons] = useState<Array<any>>([]);
    const [inspectionStatus, setInspectionStatus] = useState<Array<any>>([]);
    const [authToken , setAuthToken] = useState("")
    const [authUserID , setAuthUserID] = useState("")
    const [userId, setUserId] = useState("");
    const [equipmetTypesList, setEquipmentTypesList] = useState<Array<any>>([]);
    const currentYear = new Date().getFullYear();
    const [fromdate, setFromDate] = useState(dateType === "date" ? currentDate : dateTime);
    const [toDate, setToDate] = useState(dateType === "date" ? currentDate : endDateTime)
    const years = Array.from(new Array(30), (val, index) => currentYear + index);
    const date7DaysAgo = new Date();
    date7DaysAgo.setDate(date7DaysAgo.getDate() - 7);
    const formattedDate7DaysAgo = date7DaysAgo.toISOString().split("T")[0];
    const [sevendayfromdate, setSevendayFromDate] = useState(dateType === "date" ? formattedDate7DaysAgo : dateTime);
    useEffect(() => {

      var storedData = localStorage.getItem('userDetails');

        if(storedData) {
          var storedObject = JSON.parse(storedData);
          setUserId(storedObject[0].id)
          setAuthToken(storedObject[0].x_auth_token)
          setAuthUserID(storedObject[0].x_userid)
        }
        if(columns.some((column : any) => column.Header === 'tableCommon.material_type')) {
          listDropDownType()
        }
        if(columns.some((column : any) => column.Header === 'tableCommon.department')) {
          getDepartmentList()
        }
        if(columns.some((column : any) => column.Header === 'tableCommon.equipment_type')) {
          listEquipmentTypes()
        }
        if(columns.some((column : any) => column.Header === 'tableCommon.role')) {
          getUserRoleList()
        }
        if(columns.some((column : any) => column.Header === 'tableCommon.exception_reason')) {
          getExceptionReasons()
        }
        if(columns.some((column : any) => column.Header === 'scheduleOperator.tableColumns.activity') ||
          columns.some((column : any) => column.Header === 'plateInventory.tableColumns.visualInspectionStatus')) {
          getVisualInspectionStatus()
        }
    }, [])

      const handleSelectChange = (selectedOption : any) => {
        if (selectedOption) {
          handleRoleChange("search", selectedOption?.value)
          handleRoleChange("batch_code", selectedOption?.value);
        } else {
            handleRoleChange("search", "")
            handleRoleChange("batch_code", ""); 
        }
      };

      const handleLotCodeChange = (selectedOption : any) => {
        if (selectedOption) {
          handleRoleChange("lot_code", selectedOption?.value)
        } else {
            handleRoleChange("lot_code", "") 
        }
      };

      const handleAreaType = (selectedOption : any) => {
        if (selectedOption) {
          handleRoleChange("Area Type", selectedOption?.value)
        } else {
          handleRoleChange("Area Type", "")
        }
      };

      const handleFromDateChange = (e : any) => {
        handleRoleChange("FromDate", e.target.value)
        setFromDate(e.target.value)
        setSevendayFromDate(e.target.value)
        setToDate("")
      }

      const handleToDateChange = (e : any) => {
        handleRoleChange("ToDate", e.target.value)
        setToDate(e.target.value)
      }

      const getDepartmentList = async () => {
        try {
          const response = await userRelatedService.getUserDepartmentList();
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  console.log(response.data, "respose--->getUserDepartmentList")
                  let rowData: any = response.data.map((element: any, index: number) => {
  
                    return { 
                        slno: index + 1, 
                        department_name: element.department_name,
                        id : element.id,
                        status : element.status,
                    };
                  });
                  setDepartmentList(rowData);
                }
              } catch (error) {
                console.log(error);
              }
          }
        } catch (error) {
          console.error('user response', error);
        }
      }

      const listDropDownType = async () => {
        try {
          const response = await rawMaterials.listDropDownType(authToken ,authUserID);
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any, index: number) => {
    
                    return { 
                        slno: index + 1, 
                        material_type: element.material_type,
                        material_type_code: element.material_type_code,
                        id : element.id,
                        status : element.status,
                    };
                  });
                  setMaterialType(rowData);
                }
              } catch (error) {
                console.log(error);
              }
          }
        } catch (error) {
          console.error('user response', error);
        }
      }

      const listEquipmentTypes = async () => {
        try {
          const response = await equipments.listEquipmentTypes();
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any, index: number) => {
    
                    return { 
                        slno: index + 1, 
                        id : element.id,
                        type_name: element.type_name,
                    };
                  });
                  setEquipmentTypesList(rowData)
                }
              } catch (error) {
                console.log(error);
              }
          }
        } catch (error) {
          console.error('user response', error);
        }
      }
  
      const getUserRoleList = async () => {
        try {
          const response = await userRelatedService.getUserRoleList();
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any, index: number) => {
  
                    return { 
                      slno: index + 1, 
                      title: element.title,
                      id : element.id,
                      status : element.status,
                    };
                  });
                  setUserRoleList(rowData);
                }
              } catch (error) {
                console.log(error);
              }
          }
        } catch (error) {
          console.error('user response', error);
        }
      }

      const areaType = () => {
        if (tableHead.toLowerCase().includes("location")) {
          return "Location Type";
        }else if (tableHead.toLowerCase().includes("incident / deviation")){
          return "Activity / Process";
        } else {
          return "Area Type";
        }
      }

      const getExceptionReasons = async () => {
        try {
          const response = await plateRelatedService.getExceptionReasons();
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any, index: number) => {
                    if(element.status === "active"){
                      return { 
                          id: element.id ,
                          reason: element.reason ,
                          status: element.status ,
                      };
                    }});
                  setExceptionReasons(rowData);
                }
              } catch (error) {
                console.log(error); 
              }
          }
        } catch (error) {
          console.error('Plate response', error);
        }
      }

      const getVisualInspectionStatus = async () => {
        try {
          const response = await plateRelatedService.getVisualInspectionStatus();
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any, index: number) => {
                    // if(element.status === "active"){
                      return { 
                          id: element.status_keyword,
                          reason: element.status_name,
                          // status: element.status_name ,
                      };
                    });
                    setInspectionStatus(rowData);
                }
              } catch (error) {
                console.log(error); 
              }
          }
        } catch (error) {
          console.error('Plate response', error);
        }
      }


  return (
    <>
             {columns.some((column : any) => column.Header === 'Batch ID') && (
                <select className='areaTypeSelect mx-3 ' >
                    <option>Batch ID</option>
                </select>
            )}
           {columns.some((column : any) => column.Header === 'tableCommon.area_type' || column.Header === 'tableCommon.location_type' || column.Header === 'Activity / Process' ) && (
            <div>
            <div>
                <label htmlFor="areaTypeSelect" className='filterLabel'>{areaType()}</label> 
            </div>
            <div>
                <select id="areaTypeSelect" className='areaTypeSelect mx-3' onChange={(e) => handleRoleChange("AreaType", e.target.value)}>
                    <option value={0}>Select</option>
                    {areaList?.map((obj, index) => (
                        <option key={index} value={obj?.id}>
                            {obj?.title}
                        </option>
                    ))}
                </select>
            </div>
            </div>
            )}
            {columns.some((column : any) => column.Header === 'Reason' ) && (
            <div>
            <div>
                <label htmlFor="areaTypeSelect" className='filterLabel'>Reason</label> 
            </div>
            <div>
                <select id="areaTypeSelect" className='areaTypeSelect mx-3' onChange={(e) => handleRoleChange("Reason", e.target.value)}>
                    <option value={0}>Select</option>
                    {ReasonList?.map((obj, index) => (
                        <option key={index} value={obj?.id}>
                            {obj?.reason}
                        </option>
                    ))}
                </select>
            </div>
            </div>
            )}
            {columns.some((column : any) => column.Header === 'Incubated by' || column.Header === 'Autoclaved by') && (
              <div>
              <div>
                <label htmlFor="areaTypeSelect" className='filterLabel'>Activity Type</label> 
              </div>
              <div>
              <select className='areaTypeSelect mx-1 py-2 mb-2' onChange={(e) => handleRoleChange("Activity Type", e.target.value)} >
                    <option>Select</option>
                    {/* <option value={9}>Exposure Completed</option>
                    <option value={5}>Personnel Monitoring</option>
                    <option value={25}>Raw Material Test</option>
                    <option value={24}>Sampling Plate</option>
                    <option value={28}>Surface Monitoring Plate</option>
                    <option value={31}>Surface Monitoring - SWAB Collection</option> */}
                     {ActivityType?.map((obj, index) => (
                        <option key={index} value={obj?.id}>
                            {obj?.name}
                        </option>
                    ))}
                </select>
                </div>
                </div>
            )}

            {showPlateTypeFilter && (
              <div className='mx-3'>
              <div>
                <label htmlFor="areaTypeSelect" className='filterLabel'>Activity Type</label> 
              </div>
              <div>
              <select className='areaTypeSelect mx-1 py-2 mb-2' onChange={(e) => handleRoleChange("Plate Type", e.target.value)} >
                    <option value={0}>Select</option>
                    <option value={2}>Exposure Plate</option>
                    <option value={3}>Personnel Monitoring Plate</option>
                    <option value={4}>Sampling Plate</option>
                    <option value={5}>Material Test Plate</option>
                    <option value={6}>Surface Monitoring Plate</option>
                    <option value={7}>Surface Monitoring - SWAB Collection</option>
                </select>
                </div>
                </div>
            )}

            {columns.some((column : any) => column.Header === 'Incubated by' || column.Header === 'Autoclaved by') && (
            <div>
            <div>
                <label htmlFor="areaTypeSelect" className='filterLabel'>Status Filter</label> 
            </div>
            <div>
                <select className='areaTypeSelect mx-1 py-2 mb-2' onChange={(e) => handleRoleChange("Area Name", e.target.value)} >
                    <option>Status</option>
                    <option value={9}>Pending</option>
                    <option value={12}>Inprogress</option>
                    <option value={13}>Completed</option>
                </select>
              </div>
            </div>
            )}
            {columns.some((column : any) => column.Header === 'Incubated by') && (
                // <select className='areaTypeSelect mx-3 ' onChange={(e) => handleRoleChange("Area Type", e.target.value)}>
                //     <option value={0}>Area Type</option>
                //     <option value={6}>Exposure Area</option>
                //     <option value={10}>FingerDab Area</option>
                // </select>
                <div style={{"zIndex":"10"}}>
                <div>
                    <label htmlFor="areaTypeSelect" className='filterLabel'>Area Type</label> 
                </div>
                <div>
                <div style={{"zIndex":"10"}}>
                <Select
                   options={areaListNumber}
                   onChange={handleAreaType}
                   isClearable
                   placeholder="Select"
                   isSearchable={true} 
                   className='areaTypeSelect2 me-3'
                 />
              </div>
             </div>
              </div>
            )}
             {columns.some((column : any) => column.Header === 'Plate Serial Num') && (
                <select className='areaTypeSelect mx-3 '>
                    <option>Plate Serial</option>
                </select>
            )}
            {columns.some((column : any) => column.Header === 'tableCommon.role') && (
            <div>
            <div>
                <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Role</label> 
            </div>
            <div>
                <select className='areaTypeSelect me-3' onChange={(e) => handleRoleChange("Role", e.target.value)} >
                    <option value={0}>Select</option>
                    {userRoleList.map((obj, index) => (
                        <option key={index} value={obj?.id}>
                          {obj?.title}
                        </option>
                    ))}
                </select>
            </div>
            </div>
            )}

            {columns.some((column : any) => column.Header === 'tableCommon.department') && (
             <div>
             <div>
                 <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Department</label> 
             </div>
             <div>  
                <select className='areaTypeSelect me-3'  onChange={(e) => handleRoleChange("Department", e.target.value)} >
                    <option value={0}>Select</option>
                    {departmentList.map((obj, index) => (
                        <option key={index} value={obj?.id}>
                          {obj?.department_name}
                        </option>
                    ))}
                </select>
              </div>
              </div>
            )}

            {columns.some((column : any) => column.Header === 'tableCommon.material_type') && (
             <div>
             <div>
                 <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Material Type</label> 
             </div>
             <div>  
                <select className='areaTypeSelect me-3'  onChange={(e) => handleRoleChange("MaterialId", e.target.value)} >
                    <option value={0}>Select</option>
                    {materialType.map((obj, index) => (
                        <option key={index} value={obj?.id}>
                          {obj?.material_type}
                        </option>
                    ))}
                </select>
              </div>
              </div>
            )}

            {columns.some((column : any) => column.Header === 'tableCommon.equipment_type') && (
             <div>
             <div>
                 <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Equipment Type</label> 
             </div>
             <div>  
                <select className='areaTypeSelect me-3'  onChange={(e) => handleRoleChange("EquipmentType", e.target.value)} >
                    <option value={0}>Select</option>
                    {equipmetTypesList.map((obj, index) => (
                        <option key={index} value={obj?.id}>
                          {obj?.type_name}
                        </option>
                    ))}
                </select>
              </div>
              </div>
            )}

            {columns.some((column : any) => column.Header === 'Between Duration') && (
                <select className='areaTypeSelect mx-3 '>
                    <option>Duration Time</option>
                </select>
            )}
             {columns.some((column : any) => column.Header === 'Duration Name') && (
                <select className='areaTypeSelect mx-3 '>
                    <option>Duration Name</option>
                </select>
            )}
             {columns.some((column : any) => column.Header === 'Schedule') && (
                <select className='areaTypeSelect mx-3 '>
                    <option>Schedule</option>
                </select>
            )}
            {columns.some((column : any) => column.Header === 'tableCommon.batch_code') && showBatchFilter && (
               <div style={{"zIndex":"10"}}>
               <div>
                   <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Batch Code</label> 
               </div>
               <div>
              <div style={{"zIndex":"10"}}>
                    <Select
                      options={plateBatch}
                      onChange={handleSelectChange}
                      placeholder="Select"
                      isSearchable={true} 
                      isClearable
                      className='areaTypeSelect2 me-3'
                    />
              </div>
              </div>
              </div>
            )}
            {columns.some((column : any) => column.Header === 'tableCommon.seriel_number') && showSerielNoFilter && (
               <div style={{"zIndex":"10"}}>
               <div>
                   <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Serial Number</label> 
               </div>
               <div>
               <div style={{"zIndex":"10"}}>
                     <Select
                        options={plateSerialNumber}
                        onChange={handleSelectChange}
                        isClearable
                        placeholder="Select"
                        isSearchable={true} 
                        className='areaTypeSelect2 me-3'
                      />
              </div>
              </div>
              </div>
            )}

            {columns.some((column : any) => column.Header === 'Lot Code') && showLotCodeFilter && (
                          <div style={{"zIndex":"10"}}>
                          <div>
                              <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Lot Code</label> 
                          </div>
                          <div>
                          <div style={{"zIndex":"10"}}>
                                <Select
                                    options={lotCodeNumber}
                                    onChange={handleLotCodeChange}
                                    isClearable
                                    placeholder="Select"
                                    isSearchable={true} 
                                    className='areaTypeSelect2 me-3'
                                  />
                          </div>
                          </div>
                          </div>
            )}


            {showPlateStatus && (
               <div>
               <div>
                   <label htmlFor="areaTypeSelect" className='filterLabel'>Status</label> 
               </div>
               <div>
                <select className='areaTypeSelect mx-3' onChange={(e) => handleRoleChange("status", e.target.value)}>
                    {/* <option value={0}>Status</option>
                    <option value={1}>New</option>
                    <option value={2}>Qa Inprogress</option>
                    <option value={3}>Qa Approved</option>
                    <option value={4}>Qa Rejected</option>
                    <option value={5}>Finger Dab Inprogress</option>
                    <option value={6}>Finger Dab Approved</option>
                    <option value={7}>Finger Dab Rejected</option>
                    <option value={8}>Ep Inprogress</option>
                    <option value={9}>Ep Approved</option>
                    <option value={10}>Ep Rejected</option>
                    <option value={12}>Incubation Inprogress</option>
                    <option value={13}>Incubation Completed</option>
                    <option value={14}>Autoclave Inprogress</option>
                    <option value={15}>Autoclave Completed</option>
                    <option value={16}>Exception Requested</option>
                    <option value={17}>Damaged</option>
                    <option value={18}>Discarded</option>
                    <option value={19}>Exception Rejected</option>
                    <option value={20}>Lab Inprogress</option>
                    <option value={21}>Lab Completed</option>
                    <option value={22}>Lab Approved</option>
                    <option value={23}>Lab Rejected</option> */}
                    <option value={""}>Status</option>
                    <option value={0}>Quarantine</option>
                    <option value={5}>Waiting for QA Approval</option>  
                    <option value={1}>QA Approved </option>
                    <option value={4}>QA Rejected</option>
                    <option value={3}>Activity Inprogress</option> 
                    <option value={6}>Discard</option>                                                                               
                </select>
                </div>
                </div>
            )}
            {/* {columns.some((column : any) => column.Header === 'Date') && (
                <input
                    type="date"
                    id="dateInput"
                    name="dateInput"
                    pattern="\d{4}-\d{2}-\d{2}"
                    className='areaTypeSelect me-3'
                    onChange={(e) => handleRoleChange("search", e.target.value)}
              />
            )} */}
            {columns.some((column : any) => column.Header === 'tableCommon.exception_reason') && (
               <div>
               <div>
                   <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Exception Reason</label> 
               </div>
               <div>
              <select className='areaTypeSelect mx-1 py-2 mb-2' onChange={(e) => handleRoleChange("ExceptionReason", e.target.value)} >
                  <option value={0}>Select</option>
                  {exceptionReasons?.map((obj, index) => (
                      <option key={index} value={obj?.id}>
                        {obj?.reason}
                      </option>
                  ))}
              </select>
              </div>
              </div>
          )}

          {columns.some((column : any) => column.Header === 'plateInventory.tableColumns.visualInspectionStatus') && (
               <div>
               <div>
                   <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Visual Inspection Status</label> 
               </div>
               <div>
              <select className='areaTypeSelect mx-1 py-2 mb-2' onChange={(e) => handleRoleChange("visualInspectionStatus", e.target.value)} >
                  <option value="failed,passed,pending,Not required">Select</option>
                  {inspectionStatus?.map((obj, index) => (
                      <option key={index} value={obj?.id}>
                        {obj?.reason}
                      </option>
                  ))}
              </select>
              </div>
              </div>
          )}

        {columns.some((column : any) => column.Header === 'scheduleOperator.tableColumns.production_schedule') && (
               <div>
               <div>
                   <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Production Schedule</label> 
               </div>
               <div>
              <select className='areaTypeSelect mx-1 py-2 mb-2' onChange={(e) => handleRoleChange("productionSchedule", e.target.value)} >
                  <option value="">Select</option>
                  {prodScheduleList?.map((val) => (
                  <option key={val.id} value={val.id}>
                    {val.production_schedule_name}
                  </option>
                ))}
              </select>
              </div>
              </div>
          )}

        {columns.some((column : any) => column.Header === 'scheduleOperator.tableColumns.activity') && (
               <div>
               <div>
                   <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Activity</label> 
               </div>
               <div>
              <select className='areaTypeSelect mx-1 py-2 mb-2' onChange={(e) => handleRoleChange("activity", e.target.value)} >
                  <option value="failed,passed,pending,Not required">Select</option>
                  {inspectionStatus?.map((obj, index) => (
                      <option key={index} value={obj?.id}>
                        {obj?.reason}
                      </option>
                  ))}
              </select>
              </div>
              </div>
          )}

          {columns.some((column : any) => column.Header === 'scheduleFrequency.tableColumns.activity_type_name') && (
               <div>
               <div>
                   <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Activity Type</label> 
               </div>
               <div>
              <select className='areaTypeSelect mx-1 py-2 mb-2' onChange={(e) => handleRoleChange("activityType", e.target.value)} >
                  <option value="">Select</option>
                  {ActivityType?.map((obj, index) => (
                        <option key={index} value={obj?.id}>
                            {obj?.name}
                        </option>
                  ))}
              </select>
              </div>
              </div>
          )}

          {columns.some((column : any) => column.Header === 'Grade Type') && (
               <div>
               <div>
                   <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Grade Type</label> 
               </div>
               <div>
              <select className='areaTypeSelect mx-1 py-2 mb-2' onChange={(e) => handleRoleChange("gradeType", e.target.value)} >
                  <option value="">Select</option>
                  {GradeTypes?.map((obj, index) => (
                        <option key={index} value={obj?.id}>
                            {obj?.grade}
                        </option>
                  ))}
              </select>
              </div>
              </div>
          )}

          {columns.some((column : any) => column.Header === 'scheduleOperator.tableColumns.process_name') && (
               <div>
               <div>
                   <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Process</label> 
               </div>
               <div>
              <select className='areaTypeSelect mx-1 py-2 mb-2' onChange={(e) => handleRoleChange("process", e.target.value)} >
                  <option value="">Select</option>
                  {process?.map((obj, index) => (
                      <option key={index} value={obj?.id}>
                        {obj?.name}
                      </option>
                  ))}
              </select>
              </div>
              </div>
          )}

          {columns.some((column : any) => column.Header === 'scheduleProduction.tableColumns.area_name') && (
               <div>
               <div>
                   <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Area</label> 
               </div>
               <div>
              <select className='areaTypeSelect mx-1 py-2 mb-2' onChange={(e) => handleRoleChange("area", e.target.value)} >
                  <option value="">Select</option>
                  {areaDropList?.map((areaList: any) => (
                    <option key={areaList.id} value={areaList.id}>
                      {areaList.name}
                    </option>
                  ))}
              </select>
              </div>
              </div>
          )}

          {columns.some((column : any) => column.Header === 'generatePlateQr.tableColumns.location') && (
               <div>
               <div>
                   <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Location</label> 
               </div>
               <div>
              <select className='areaTypeSelect mx-1 py-2 mb-2' onChange={(e) => handleRoleChange("location", e.target.value)} >
                  <option value="">Select</option>
                  {locationList?.map((obj, index) => (
                      <option key={index} value={obj?.id}>
                        {obj?.name}
                      </option>
                  ))}
              </select>
              </div>
              </div>
          )}

        {columns.some((column : any) => column.Header === 'scheduleOperator.tableColumns.status') && (
               <div>
               <div>
                   <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Status</label> 
               </div>
               <div>
              <select className='areaTypeSelect mx-1 py-2 mb-2' onChange={(e) => handleRoleChange("status", e.target.value)} >
                  <option value="active,inactive">Select</option>
                  <option value="active">Active</option>
                  <option value="inactive">In Active</option>
              </select>
              </div>
              </div>
          )}

          {columns.some((column : any) => column.Header === 'scheduleOperator.tableColumns.assignee') && (
               <div>
               <div>
                   <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Assignee</label> 
               </div>
               <div>
              <select className='areaTypeSelect mx-1 py-2 mb-2' onChange={(e) => handleRoleChange("assignee", e.target.value)} >
                  <option value="">Select</option>
                  {assignee?.map((obj, index) => (
                      <option key={index} value={obj?.id}>
                        {obj?.name}
                      </option>
                  ))}
              </select>
              </div>
              </div>
          )}

          {columns.some((column : any) => column.Header === 'scheduleFrequency.tableColumns.area_grade_name') && (
               <div>
               <div>
                   <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Area Grade</label> 
               </div>
               <div>
              <select className='areaTypeSelect mx-1 py-2 mb-2' onChange={(e) => handleRoleChange("areaGrade", e.target.value)} >
                  <option value="">Select</option>
                  {areaGrade?.map((obj, index) => (
                      <option key={index} value={obj?.id}>
                        {obj?.grade}
                      </option>
                  ))}
              </select>
              </div>
              </div>
          )}

          {columns.some((column : any) => column.Header === 'scheduleFrequency.tableColumns.location_category_name') && (
               <div>
               <div>
                   <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Location Category</label> 
               </div>
               <div>
              <select className='areaTypeSelect mx-1 py-2 mb-2' onChange={(e) => handleRoleChange("locationCategory", e.target.value)} >
                  <option value="">Select</option>
                  {locationCategory?.map((categoryList: any) => (
                    <option key={categoryList.id} value={categoryList.id}>
                      {categoryList.categoryName}
                    </option>
                  ))}
              </select>
              </div>
              </div>
          )}

          {columns.some((column : any) => column.Header === 'scheduleFrequency.tableColumns.productionType') && (
               <div>
               <div>
                   <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Production Type</label> 
               </div>
               <div>
              <select className='areaTypeSelect mx-1 py-2 mb-2' onChange={(e) => handleRoleChange("productionType", e.target.value)} >
                  <option value="">Select</option>
                  <option value="operational">Operational</option>
                  <option value="non-operational">Non-operational</option>
                  {/* <option value="ongoing">Ongoing</option> */}
              </select>
              </div>
              </div>
          )}


          {showFromDateFilter && (
               <div style={{width:"140px",marginRight: "15px"}}>
               <div>
                   <label htmlFor="areaTypeSelect" className='filterLabelLeft'>From Date</label>
               </div>
               <div>
              <input
              id="selectedDate"
              className="form-control"
              name="fromDate"
              // type={dateType}
              type="date"
              onChange={(e) => handleFromDateChange(e)}
              defaultValue={moment(sevendayfromdate).format("YYYY-MM-DD")}
              onKeyDown={(e) => {
                // Allow navigation keys (e.g., Tab, Arrow keys) and Enter to open the date picker
                const allowedKeys = [
                  "Tab",
                  "Enter",
                  "Escape",
                ];
                if (!allowedKeys.includes(e.key)) {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => e.preventDefault()}
              />
              </div>
              </div>
          )}

          {showFromDateLabApproval && (
               <div style={{width:"140px",marginRight: "15px"}}>
               <div>
                   <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Lab From Date</label> 
               </div>
               <div>
              <input
              id="selectedDate"
              className="form-control"
              name="fromDate"
              type={dateType}
              onChange={(e) => handleFromDateChange(e)}
              defaultValue={sevendayfromdate}
              onKeyDown={(e) => {
                // Allow navigation keys (e.g., Tab, Arrow keys) and Enter to open the date picker
                const allowedKeys = [
                  "Tab",
                  "Enter",
                  "Escape",
                ];
                if (!allowedKeys.includes(e.key)) {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => e.preventDefault()}
              />
              </div>
              </div>
          )}

          {showInventoryFromDateFilter && (
               <div style={{width:"140px",marginRight: "15px"}}>
               <div>
                   <label htmlFor="areaTypeSelect" className='filterLabelLeft'>From Date</label> 
               </div>
               <div>
              <input
              id="selectedDate"
              className="form-control"
              name="fromDate"
              type={dateType}
              onChange={(e) => handleFromDateChange(e)}
              defaultValue={sevendayfromdate}
              onKeyDown={(e) => {
                // Allow navigation keys (e.g., Tab, Arrow keys) and Enter to open the date picker
                const allowedKeys = [
                  "Tab",
                  "Enter",
                  "Escape",
                ];
                if (!allowedKeys.includes(e.key)) {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => e.preventDefault()}
              />
              </div>
              </div>
          )}


          {showToDateFilter && (
               <div style={{width:"140px"}}>
               <div>
                   <label htmlFor="areaTypeSelect" className='filterLabelLeft'>To Date</label> 
               </div>
               <div>
              <input
              id="selectedDate"
              className="form-control"
              name="fromDate"
              // type={dateType}
              type="date"
              defaultValue={moment(toDate).format("YYYY-MM-DD")}
              value={moment(toDate).format("YYYY-MM-DD")}
              min={showInventoryFromDateFilter ? moment(sevendayfromdate).format("YYYY-MM-DD") : moment(fromdate).format("YYYY-MM-DD")}
              onChange={(e) => handleToDateChange(e)}
              onKeyDown={(e) => {
                // Allow navigation keys (e.g., Tab, Arrow keys) and Enter to open the date picker
                const allowedKeys = [
                  "Tab",
                  "Enter",
                  "Escape",
                ];
                if (!allowedKeys.includes(e.key)) {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => e.preventDefault()}
              />
              </div>
              </div>
          )}

          {showToDateLabApproval && (
               <div style={{width:"140px"}}>
               <div>
                   <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Lab To Date</label> 
               </div>
               <div>
              <input
              id="selectedDate"
              className="form-control"
              name="fromDate"
              type={dateType}
              defaultValue={toDate}
              value={toDate}
              min={showInventoryFromDateFilter ? sevendayfromdate : fromdate}
              onChange={(e) => handleToDateChange(e)}
              onKeyDown={(e) => {
                // Allow navigation keys (e.g., Tab, Arrow keys) and Enter to open the date picker
                const allowedKeys = [
                  "Tab",
                  "Enter",
                  "Escape",
                ];
                if (!allowedKeys.includes(e.key)) {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => e.preventDefault()}
              />
              </div>
              </div>
          )}

          {showYearFilter && (
            
                  <div>
                    <div>
                      <label htmlFor="yearSelect" className='filterLabelLeft'>Select Year</label> 
                    </div>
                    <div>
                      <select 
                        id="yearSelect" 
                        className="form-control"
                        onChange={(e) => handleRoleChange("selectedYear", e.target.value)}
                      >
                        <option value="">Select Year</option>
                        {years.map((year, index) => (
                          <option key={index} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
          )}

          {columns.some((column : any) => column.Header === 'Lab Status') && (
            <div>
            <div>
                <label htmlFor="areaTypeSelect" className='filterLabel'>Lab Status</label> 
            </div>
            <div>
                <select className='areaTypeSelect mx-1 py-2 mb-2' onChange={(e) => handleRoleChange("lab_status", e.target.value)} >
                    <option value={""}>Select</option>
                    <option value={0}>Pending for QA 1</option>
                    <option value={1}>Pending for QA 2</option>
                    <option value={12}>Pending for QA 3</option>
                </select>
              </div>
            </div>
            )}

          {columns.some((column : any) => column.Header === 'downloadPdfScreen.reportName') && (
               <div>
               <div>
                   <label htmlFor="areaTypeSelect" className='filterLabelLeft'>Report Type</label> 
               </div>
               <div>
              <select className='areaTypeSelect mx-1 py-2 mb-2' onChange={(e) => handleRoleChange("ReportType", e.target.value)} >
                  <option value={0}>Select</option>
                  {reportName?.map((obj, index) => (
                      <option key={index} value={obj?.id}>
                        {obj?.label}
                      </option>
                  ))}
              </select>
              </div>
              </div>
          )}
    </>
  )
}

export default FilterSec
